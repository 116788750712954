import axios from 'axios'
const proxyUrl = {
  production: 'https://unleash-proxy.classapp.co/proxy',
  stage: 'https://unleash-proxy.classapp.ninja/proxy',
  internal: 'https://unleash-proxy-internal.classapp.ninja/proxy'
};

export class UnleashAxios {
  /**
   * @param {string} url
   * @param {string} clientKey
   */
  constructor(url, clientKey) {
    this.url = url;
    this.clientKey = clientKey;
  }


  /**
   * @param {string} toggle
   * @param {{ organizationId?: string }} context
   */
  async isEnabled(toggle, context) {
    try {
      const response = await axios.request({
        method: 'POST',
        url: this.url,
        headers: { Authorization: this.clientKey, 'Content-Type': 'application/json' },
        data: {
          toggles: [toggle],
          context: { appName: 'Walle', ...context }
        }
      });
      const toggleFound = response.data.toggles.find((/** @type {{ name: string; }} */ t) => t.name === toggle);
      return toggleFound.enabled;
    } catch (error) {
      console.error('Unleash Error', error);
      return false;
    }
  }

  /**
   * @param {string} env
   */
  static create(env) {
    const url = proxyUrl[env] ? proxyUrl[env] : proxyUrl.internal;
    const key = 'brTxu1Yn3s1M2g6jK';
    return new UnleashAxios(url, key);
  }
};
