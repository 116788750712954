/** Extract from BrazilAPI(https://brasilapi.com.br/api/banks/v1)

This file is used as cache or backup if the call to the brasilAPI fails during execution time.
This file might need to be updated but with a very low frequency
OBS: field LABEL and VALUE were calculated by function getBrazilianBanks of the file payment.js
*/

export default [
  {
    ispb: '00000000',
    name: 'BCO DO BRASIL S.A.',
    code: 1,
    fullName: 'Banco do Brasil S.A.',
    label: '001',
    value: '001 - BCO DO BRASIL S.A.'
  },
  {
    ispb: '00000208',
    name: 'BRB - BCO DE BRASILIA S.A.',
    code: 70,
    fullName: 'BRB - BANCO DE BRASILIA S.A.',
    label: '070',
    value: '070 - BRB - BCO DE BRASILIA S.A.'
  },
  {
    ispb: '00250699',
    name: 'AGK CC S.A.',
    code: 272,
    fullName: 'AGK CORRETORA DE CAMBIO S.A.',
    label: '272',
    value: '272 - AGK CC S.A.'
  },
  {
    ispb: '00315557',
    name: 'CONF NAC COOP CENTRAIS UNICRED',
    code: 136,
    fullName: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI',
    label: '136',
    value: '136 - CONF NAC COOP CENTRAIS UNICRED'
  },
  {
    ispb: '00329598',
    name: 'ÍNDIGO INVESTIMENTOS DTVM LTDA.',
    code: 407,
    fullName: 'ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    label: '407',
    value: '407 - ÍNDIGO INVESTIMENTOS DTVM LTDA.'
  },
  {
    ispb: '00360305',
    name: 'CAIXA ECONOMICA FEDERAL',
    code: 104,
    fullName: 'CAIXA ECONOMICA FEDERAL',
    label: '104',
    value: '104 - CAIXA ECONOMICA FEDERAL'
  },
  {
    ispb: '00416968',
    name: 'BANCO INTER',
    code: 77,
    fullName: 'Banco Inter S.A.',
    label: '077',
    value: '077 - BANCO INTER'
  },
  {
    ispb: '00460065',
    name: 'COLUNA S.A. DTVM',
    code: 423,
    fullName: 'COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS',
    label: '423',
    value: '423 - COLUNA S.A. DTVM'
  },
  {
    ispb: '00517645',
    name: 'BCO RIBEIRAO PRETO S.A.',
    code: 741,
    fullName: 'BANCO RIBEIRAO PRETO S.A.',
    label: '741',
    value: '741 - BCO RIBEIRAO PRETO S.A.'
  },
  {
    ispb: '00556603',
    name: 'BANCO BARI S.A.',
    code: 330,
    fullName: 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
    label: '330',
    value: '330 - BANCO BARI S.A.'
  },
  {
    ispb: '00558456',
    name: 'BCO CETELEM S.A.',
    code: 739,
    fullName: 'Banco Cetelem S.A.',
    label: '739',
    value: '739 - BCO CETELEM S.A.'
  },
  {
    ispb: '00795423',
    name: 'BANCO SEMEAR',
    code: 743,
    fullName: 'Banco Semear S.A.',
    label: '743',
    value: '743 - BANCO SEMEAR'
  },
  {
    ispb: '00806535',
    name: 'PLANNER CV S.A.',
    code: 100,
    fullName: 'Planner Corretora de Valores S.A.',
    label: '100',
    value: '100 - PLANNER CV S.A.'
  },
  {
    ispb: '00997185',
    name: 'BCO B3 S.A.',
    code: 96,
    fullName: 'Banco B3 S.A.',
    label: '096',
    value: '096 - BCO B3 S.A.'
  },
  {
    ispb: '01023570',
    name: 'BCO RABOBANK INTL BRASIL S.A.',
    code: 747,
    fullName: 'Banco Rabobank International Brasil S.A.',
    label: '747',
    value: '747 - BCO RABOBANK INTL BRASIL S.A.'
  },
  {
    ispb: '01027058',
    name: 'CIELO S.A.',
    code: 362,
    fullName: 'CIELO S.A.',
    label: '362',
    value: '362 - CIELO S.A.'
  },
  {
    ispb: '01073966',
    name: 'CCR DE ABELARDO LUZ',
    code: 322,
    fullName: 'Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz',
    label: '322',
    value: '322 - CCR DE ABELARDO LUZ'
  },
  {
    ispb: '01181521',
    name: 'BCO COOPERATIVO SICREDI S.A.',
    code: 748,
    fullName: 'BANCO COOPERATIVO SICREDI S.A.',
    label: '748',
    value: '748 - BCO COOPERATIVO SICREDI S.A.'
  },
  {
    ispb: '01330387',
    name: 'CREHNOR LARANJEIRAS',
    code: 350,
    fullName: 'COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE',
    label: '350',
    value: '350 - CREHNOR LARANJEIRAS'
  },
  {
    ispb: '01522368',
    name: 'BCO BNP PARIBAS BRASIL S A',
    code: 752,
    fullName: 'Banco BNP Paribas Brasil S.A.',
    label: '752',
    value: '752 - BCO BNP PARIBAS BRASIL S A'
  },
  {
    ispb: '01634601',
    name: 'CCCM UNICRED CENTRAL RS',
    code: 91,
    fullName: 'CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S',
    label: '091',
    value: '091 - CCCM UNICRED CENTRAL RS'
  },
  {
    ispb: '01658426',
    name: 'CECM COOPERFORTE',
    code: 379,
    fullName: 'COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU',
    label: '379',
    value: '379 - CECM COOPERFORTE'
  },
  {
    ispb: '01701201',
    name: 'KIRTON BANK',
    code: 399,
    fullName: 'Kirton Bank S.A. - Banco Múltiplo',
    label: '399',
    value: '399 - KIRTON BANK'
  },
  {
    ispb: '01800019',
    name: 'PORTOCRED S.A. - CFI',
    code: 108,
    fullName: '"PORTOCRED S.A. - CREDITO',
    label: '108',
    value: '108 - PORTOCRED S.A. - CFI'
  },
  {
    ispb: '01852137',
    name: 'BBC LEASING',
    code: 378,
    fullName: 'BBC LEASING S.A. - ARRENDAMENTO MERCANTIL',
    label: '378',
    value: '378 - BBC LEASING'
  },
  {
    ispb: '01858774',
    name: 'BCO BV S.A.',
    code: 413,
    fullName: 'BANCO BV S.A.',
    label: '413',
    value: '413 - BCO BV S.A.'
  },
  {
    ispb: '02038232',
    name: 'BANCO SICOOB S.A.',
    code: 756,
    fullName: 'BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB',
    label: '756',
    value: '756 - BANCO SICOOB S.A.'
  },
  {
    ispb: '02276653',
    name: 'TRINUS CAPITAL DTVM',
    code: 360,
    fullName: 'TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    label: '360',
    value: '360 - TRINUS CAPITAL DTVM'
  },
  {
    ispb: '02318507',
    name: 'BCO KEB HANA DO BRASIL S.A.',
    code: 757,
    fullName: 'BANCO KEB HANA DO BRASIL S.A.',
    label: '757',
    value: '757 - BCO KEB HANA DO BRASIL S.A.'
  },
  {
    ispb: '02332886',
    name: 'XP INVESTIMENTOS CCTVM S/A',
    code: 102,
    fullName: '"XP INVESTIMENTOS CORRETORA DE CÂMBIO',
    label: '102',
    value: '102 - XP INVESTIMENTOS CCTVM S/A'
  },
  {
    ispb: '02398976',
    name: 'UNIPRIME NORTE DO PARANÁ - CC',
    code: 84,
    fullName: 'UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA',
    label: '084',
    value: '084 - UNIPRIME NORTE DO PARANÁ - CC'
  },
  {
    ispb: '02685483',
    name: 'CM CAPITAL MARKETS CCTVM LTDA',
    code: 180,
    fullName: '"CM CAPITAL MARKETS CORRETORA DE CÂMBIO',
    label: '180',
    value: '180 - CM CAPITAL MARKETS CCTVM LTDA'
  },
  {
    ispb: '02801938',
    name: 'BCO MORGAN STANLEY S.A.',
    code: 66,
    fullName: 'BANCO MORGAN STANLEY S.A.',
    label: '066',
    value: '066 - BCO MORGAN STANLEY S.A.'
  },
  {
    ispb: '02819125',
    name: 'UBS BRASIL CCTVM S.A.',
    code: 15,
    fullName: '"UBS Brasil Corretora de Câmbio',
    label: '015',
    value: '015 - UBS BRASIL CCTVM S.A.'
  },
  {
    ispb: '02992317',
    name: 'TREVISO CC S.A.',
    code: 143,
    fullName: 'Treviso Corretora de Câmbio S.A.',
    label: '143',
    value: '143 - TREVISO CC S.A.'
  },
  {
    ispb: '03012230',
    name: 'HIPERCARD BM S.A.',
    code: 62,
    fullName: 'Hipercard Banco Múltiplo S.A.',
    label: '062',
    value: '062 - HIPERCARD BM S.A.'
  },
  {
    ispb: '03017677',
    name: 'BCO. J.SAFRA S.A.',
    code: 74,
    fullName: 'Banco J. Safra S.A.',
    label: '074',
    value: '074 - BCO. J.SAFRA S.A.'
  },
  {
    ispb: '03046391',
    name: 'UNIPRIME CENTRAL CCC LTDA.',
    code: 99,
    fullName: 'UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.',
    label: '099',
    value: '099 - UNIPRIME CENTRAL CCC LTDA.'
  },
  {
    ispb: '03215790',
    name: 'BCO TOYOTA DO BRASIL S.A.',
    code: 387,
    fullName: 'Banco Toyota do Brasil S.A.',
    label: '387',
    value: '387 - BCO TOYOTA DO BRASIL S.A.'
  },
  {
    ispb: '03311443',
    name: 'PARATI - CFI S.A.',
    code: 326,
    fullName: '"PARATI - CREDITO',
    label: '326',
    value: '326 - PARATI - CFI S.A.'
  },
  {
    ispb: '03323840',
    name: 'BCO ALFA S.A.',
    code: 25,
    fullName: 'Banco Alfa S.A.',
    label: '025',
    value: '025 - BCO ALFA S.A.'
  },
  {
    ispb: '03502968',
    name: 'PI DTVM S.A.',
    code: 315,
    fullName: 'PI Distribuidora de Títulos e Valores Mobiliários S.A.',
    label: '315',
    value: '315 - PI DTVM S.A.'
  },
  {
    ispb: '03532415',
    name: 'BCO ABN AMRO S.A.',
    code: 75,
    fullName: 'Banco ABN Amro S.A.',
    label: '075',
    value: '075 - BCO ABN AMRO S.A.'
  },
  {
    ispb: '03609817',
    name: 'BCO CARGILL S.A.',
    code: 40,
    fullName: 'Banco Cargill S.A.',
    label: '040',
    value: '040 - BCO CARGILL S.A.'
  },
  {
    ispb: '03751794',
    name: 'TERRA INVESTIMENTOS DTVM',
    code: 307,
    fullName: 'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
    label: '307',
    value: '307 - TERRA INVESTIMENTOS DTVM'
  },
  {
    ispb: '03973814',
    name: 'SERVICOOP',
    code: 190,
    fullName: 'SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN',
    label: '190',
    value: '190 - SERVICOOP'
  },
  {
    ispb: '04062902',
    name: 'VISION S.A. CC',
    code: 296,
    fullName: 'VISION S.A. CORRETORA DE CAMBIO',
    label: '296',
    value: '296 - VISION S.A. CC'
  },
  {
    ispb: '04184779',
    name: 'BANCO BRADESCARD',
    code: 63,
    fullName: 'Banco Bradescard S.A.',
    label: '063',
    value: '063 - BANCO BRADESCARD'
  },
  {
    ispb: '04257795',
    name: 'NOVA FUTURA CTVM LTDA.',
    code: 191,
    fullName: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
    label: '191',
    value: '191 - NOVA FUTURA CTVM LTDA.'
  },
  {
    ispb: '04307598',
    name: 'FIDUCIA SCMEPP LTDA',
    code: 382,
    fullName: 'FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L',
    label: '382',
    value: '382 - FIDUCIA SCMEPP LTDA'
  },
  {
    ispb: '04332281',
    name: 'GOLDMAN SACHS DO BRASIL BM S.A',
    code: 64,
    fullName: 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.',
    label: '064',
    value: '064 - GOLDMAN SACHS DO BRASIL BM S.A'
  },
  {
    ispb: '04632856',
    name: 'CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.',
    code: 97,
    fullName: 'Credisis - Central de Cooperativas de Crédito Ltda.',
    label: '097',
    value: '097 - CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.'
  },
  {
    ispb: '04715685',
    name: 'CCM DESP TRÂNS SC E RS',
    code: 16,
    fullName: 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
    label: '016',
    value: '016 - CCM DESP TRÂNS SC E RS'
  },
  {
    ispb: '04814563',
    name: 'BCO SOROCRED S.A. - BM',
    code: 299,
    fullName: 'BANCO SOROCRED S.A. - BANCO MÚLTIPLO',
    label: '299',
    value: '299 - BCO SOROCRED S.A. - BM'
  },
  {
    ispb: '04866275',
    name: 'BANCO INBURSA',
    code: 12,
    fullName: 'Banco Inbursa S.A.',
    label: '012',
    value: '012 - BANCO INBURSA'
  },
  {
    ispb: '04902979',
    name: 'BCO DA AMAZONIA S.A.',
    code: 3,
    fullName: 'BANCO DA AMAZONIA S.A.',
    label: '003',
    value: '003 - BCO DA AMAZONIA S.A.'
  },
  {
    ispb: '04913129',
    name: 'CONFIDENCE CC S.A.',
    code: 60,
    fullName: 'Confidence Corretora de Câmbio S.A.',
    label: '060',
    value: '060 - CONFIDENCE CC S.A.'
  },
  {
    ispb: '04913711',
    name: 'BCO DO EST. DO PA S.A.',
    code: 37,
    fullName: 'Banco do Estado do Pará S.A.',
    label: '037',
    value: '037 - BCO DO EST. DO PA S.A.'
  },
  {
    ispb: '05192316',
    name: 'VIA CERTA FINANCIADORA S.A. - CFI',
    code: 411,
    fullName: '"Via Certa Financiadora S.A. - Crédito',
    label: '411',
    value: '411 - VIA CERTA FINANCIADORA S.A. - CFI'
  },
  {
    ispb: '05351887',
    name: 'ZEMA CFI S/A',
    code: 359,
    fullName: '"ZEMA CRÉDITO',
    label: '359',
    value: '359 - ZEMA CFI S/A'
  },
  {
    ispb: '05442029',
    name: 'CASA CREDITO S.A. SCM',
    code: 159,
    fullName: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
    label: '159',
    value: '159 - CASA CREDITO S.A. SCM'
  },
  {
    ispb: '05463212',
    name: 'COOP CENTRAL AILOS',
    code: 85,
    fullName: 'Cooperativa Central de Crédito - Ailos',
    label: '085',
    value: '085 - COOP CENTRAL AILOS'
  },
  {
    ispb: '05491616',
    name: 'CC POUP SER FIN CO',
    code: 400,
    fullName: '"COOPERATIVA DE CRÉDITO',
    label: '400',
    value: '400 - CC POUP SER FIN CO'
  },
  {
    ispb: '05684234',
    name: 'PLANNER SCM S.A.',
    code: 410,
    fullName: 'PLANNER SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR S.A.',
    label: '410',
    value: '410 - PLANNER SCM S.A.'
  },
  {
    ispb: '05790149',
    name: 'CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO',
    code: 114,
    fullName: 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
    label: '114',
    value: '114 - CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO'
  },
  {
    ispb: '05841967',
    name: 'CECM FABRIC CALÇADOS SAPIRANGA',
    code: 328,
    fullName: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA',
    label: '328',
    value: '328 - CECM FABRIC CALÇADOS SAPIRANGA'
  },
  {
    ispb: '06271464',
    name: 'BCO BBI S.A.',
    code: 36,
    fullName: 'Banco Bradesco BBI S.A.',
    label: '036',
    value: '036 - BCO BBI S.A.'
  },
  {
    ispb: '07207996',
    name: 'BCO BRADESCO FINANC. S.A.',
    code: 394,
    fullName: 'Banco Bradesco Financiamentos S.A.',
    label: '394',
    value: '394 - BCO BRADESCO FINANC. S.A.'
  },
  {
    ispb: '07237373',
    name: 'BCO DO NORDESTE DO BRASIL S.A.',
    code: 4,
    fullName: 'Banco do Nordeste do Brasil S.A.',
    label: '004',
    value: '004 - BCO DO NORDESTE DO BRASIL S.A.'
  },
  {
    ispb: '07450604',
    name: 'BCO CCB BRASIL S.A.',
    code: 320,
    fullName: 'China Construction Bank (Brasil) Banco Múltiplo S/A',
    label: '320',
    value: '320 - BCO CCB BRASIL S.A.'
  },
  {
    ispb: '07512441',
    name: 'HS FINANCEIRA',
    code: 189,
    fullName: '"HS FINANCEIRA S/A CREDITO',
    label: '189',
    value: '189 - HS FINANCEIRA'
  },
  {
    ispb: '07652226',
    name: 'LECCA CFI S.A.',
    code: 105,
    fullName: '"Lecca Crédito',
    label: '105',
    value: '105 - LECCA CFI S.A.'
  },
  {
    ispb: '07656500',
    name: 'BCO KDB BRASIL S.A.',
    code: 76,
    fullName: 'Banco KDB do Brasil S.A.',
    label: '076',
    value: '076 - BCO KDB BRASIL S.A.'
  },
  {
    ispb: '07679404',
    name: 'BANCO TOPÁZIO S.A.',
    code: 82,
    fullName: 'BANCO TOPÁZIO S.A.',
    label: '082',
    value: '082 - BANCO TOPÁZIO S.A.'
  },
  {
    ispb: '07693858',
    name: 'HSCM SCMEPP LTDA.',
    code: 312,
    fullName: 'HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT',
    label: '312',
    value: '312 - HSCM SCMEPP LTDA.'
  },
  {
    ispb: '07853842',
    name: 'CCR DE OURO',
    code: 286,
    fullName: 'COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO',
    label: '286',
    value: '286 - CCR DE OURO'
  },
  {
    ispb: '07945233',
    name: 'POLOCRED SCMEPP LTDA.',
    code: 93,
    fullName: 'PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
    label: '093',
    value: '093 - POLOCRED SCMEPP LTDA.'
  },
  {
    ispb: '08240446',
    name: 'CCR DE IBIAM',
    code: 391,
    fullName: 'COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM',
    label: '391',
    value: '391 - CCR DE IBIAM'
  },
  {
    ispb: '08253539',
    name: 'CCR DE SÃO MIGUEL DO OESTE',
    code: 273,
    fullName: 'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel',
    label: '273',
    value: '273 - CCR DE SÃO MIGUEL DO OESTE'
  },
  {
    ispb: '08357240',
    name: 'BCO CSF S.A.',
    code: 368,
    fullName: 'Banco CSF S.A.',
    label: '368',
    value: '368 - BCO CSF S.A.'
  },
  {
    ispb: '08561701',
    name: 'PAGSEGURO S.A.',
    code: 290,
    fullName: 'Pagseguro Internet S.A.',
    label: '290',
    value: '290 - PAGSEGURO S.A.'
  },
  {
    ispb: '08609934',
    name: 'MONEYCORP BCO DE CÂMBIO S.A.',
    code: 259,
    fullName: 'MONEYCORP BANCO DE CÂMBIO S.A.',
    label: '259',
    value: '259 - MONEYCORP BCO DE CÂMBIO S.A.'
  },
  {
    ispb: '08673569',
    name: 'F D GOLD DTVM LTDA',
    code: 395,
    fullName: "F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    label: '395',
    value: '395 - F D GOLD DTVM LTDA'
  },
  {
    ispb: '09089356',
    name: 'GERENCIANET',
    code: 364,
    fullName: 'GERENCIANET S.A.',
    label: '364',
    value: '364 - GERENCIANET'
  },
  {
    ispb: '09105360',
    name: 'ICAP DO BRASIL CTVM LTDA.',
    code: 157,
    fullName: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
    label: '157',
    value: '157 - ICAP DO BRASIL CTVM LTDA.'
  },
  {
    ispb: '09210106',
    name: 'SOCRED SA - SCMEPP',
    code: 183,
    fullName: 'SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P',
    label: '183',
    value: '183 - SOCRED SA - SCMEPP'
  },
  {
    ispb: '09274232',
    name: 'STATE STREET BR S.A. BCO COMERCIAL',
    code: 14,
    fullName: 'STATE STREET BRASIL S.A. ? BANCO COMERCIAL',
    label: '014',
    value: '014 - STATE STREET BR S.A. BCO COMERCIAL'
  },
  {
    ispb: '09313766',
    name: 'CARUANA SCFI',
    code: 130,
    fullName: '"CARUANA S.A. - SOCIEDADE DE CRÉDITO',
    label: '130',
    value: '130 - CARUANA SCFI'
  },
  {
    ispb: '09464032',
    name: 'MIDWAY S.A. - SCFI',
    code: 358,
    fullName: '"MIDWAY S.A. - CRÉDITO',
    label: '358',
    value: '358 - MIDWAY S.A. - SCFI'
  },
  {
    ispb: '09512542',
    name: 'CODEPE CVC S.A.',
    code: 127,
    fullName: 'Codepe Corretora de Valores e Câmbio S.A.',
    label: '127',
    value: '127 - CODEPE CVC S.A.'
  },
  {
    ispb: '09516419',
    name: 'BCO ORIGINAL DO AGRO S/A',
    code: 79,
    fullName: 'Banco Original do Agronegócio S.A.',
    label: '079',
    value: '079 - BCO ORIGINAL DO AGRO S/A'
  },
  {
    ispb: '09554480',
    name: 'SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.',
    code: 340,
    fullName: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.',
    label: '340',
    value: '340 - SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.'
  },
  {
    ispb: '10264663',
    name: 'BANCOSEGURO S.A.',
    code: 81,
    fullName: 'BancoSeguro S.A.',
    label: '081',
    value: '081 - BANCOSEGURO S.A.'
  },
  {
    ispb: '10398952',
    name: 'CRESOL CONFEDERAÇÃO',
    code: 133,
    fullName: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E',
    label: '133',
    value: '133 - CRESOL CONFEDERAÇÃO'
  },
  {
    ispb: '10573521',
    name: 'MERCADO PAGO',
    code: 323,
    fullName: 'MERCADOPAGO.COM REPRESENTACOES LTDA.',
    label: '323',
    value: '323 - MERCADO PAGO'
  },
  {
    ispb: '10664513',
    name: 'BCO AGIBANK S.A.',
    code: 121,
    fullName: 'Banco Agibank S.A.',
    label: '121',
    value: '121 - BCO AGIBANK S.A.'
  },
  {
    ispb: '10690848',
    name: 'BCO DA CHINA BRASIL S.A.',
    code: 83,
    fullName: 'Banco da China Brasil S.A.',
    label: '083',
    value: '083 - BCO DA CHINA BRASIL S.A.'
  },
  {
    ispb: '10853017',
    name: 'GET MONEY CC LTDA',
    code: 138,
    fullName: 'Get Money Corretora de Câmbio S.A.',
    label: '138',
    value: '138 - GET MONEY CC LTDA'
  },
  {
    ispb: '10866788',
    name: 'BCO BANDEPE S.A.',
    code: 24,
    fullName: 'Banco Bandepe S.A.',
    label: '024',
    value: '024 - BCO BANDEPE S.A.'
  },
  {
    ispb: '11165756',
    name: 'GLOBAL SCM LTDA',
    code: 384,
    fullName: 'GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO',
    label: '384',
    value: '384 - GLOBAL SCM LTDA'
  },
  {
    ispb: '11285104',
    name: 'BIORC FINANCEIRA - CFI S.A.',
    code: 426,
    fullName: '"Biorc Financeira - Crédito',
    label: '426',
    value: '426 - BIORC FINANCEIRA - CFI S.A.'
  },
  {
    ispb: '11476673',
    name: 'BANCO RANDON S.A.',
    code: 88,
    fullName: 'BANCO RANDON S.A.',
    label: '088',
    value: '088 - BANCO RANDON S.A.'
  },
  {
    ispb: '11495073',
    name: 'OM DTVM LTDA',
    code: 319,
    fullName: 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    label: '319',
    value: '319 - OM DTVM LTDA'
  },
  {
    ispb: '11581339',
    name: 'MONEY PLUS SCMEPP LTDA',
    code: 274,
    fullName: 'MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT',
    label: '274',
    value: '274 - MONEY PLUS SCMEPP LTDA'
  },
  {
    ispb: '11703662',
    name: 'TRAVELEX BANCO DE CÂMBIO S.A.',
    code: 95,
    fullName: 'Travelex Banco de Câmbio S.A.',
    label: '095',
    value: '095 - TRAVELEX BANCO DE CÂMBIO S.A.'
  },
  {
    ispb: '11758741',
    name: 'BANCO FINAXIS',
    code: 94,
    fullName: 'Banco Finaxis S.A.',
    label: '094',
    value: '094 - BANCO FINAXIS'
  },
  {
    ispb: '11970623',
    name: 'BCO SENFF S.A.',
    code: 276,
    fullName: 'BANCO SENFF S.A.',
    label: '276',
    value: '276 - BCO SENFF S.A.'
  },
  {
    ispb: '12865507',
    name: 'BRK S.A. CFI',
    code: 92,
    fullName: '"BRK S.A. Crédito',
    label: '092',
    value: '092 - BRK S.A. CFI'
  },
  {
    ispb: '13009717',
    name: 'BCO DO EST. DE SE S.A.',
    code: 47,
    fullName: 'Banco do Estado de Sergipe S.A.',
    label: '047',
    value: '047 - BCO DO EST. DE SE S.A.'
  },
  {
    ispb: '13059145',
    name: 'BEXS BCO DE CAMBIO S.A.',
    code: 144,
    fullName: 'BEXS BANCO DE CÂMBIO S/A',
    label: '144',
    value: '144 - BEXS BCO DE CAMBIO S.A.'
  },
  {
    ispb: '13140088',
    name: 'ACESSO SOLUCOES PAGAMENTO SA',
    code: 332,
    fullName: 'Acesso Soluções de Pagamento S.A.',
    label: '332',
    value: '332 - ACESSO SOLUCOES PAGAMENTO SA'
  },
  {
    ispb: '13220493',
    name: 'BR PARTNERS BI',
    code: 126,
    fullName: 'BR Partners Banco de Investimento S.A.',
    label: '126',
    value: '126 - BR PARTNERS BI'
  },
  {
    ispb: '13293225',
    name: 'ÓRAMA DTVM S.A.',
    code: 325,
    fullName: 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
    label: '325',
    value: '325 - ÓRAMA DTVM S.A.'
  },
  {
    ispb: '13370835',
    name: 'BPP IP S.A.',
    code: 301,
    fullName: 'BPP Instituição de Pagamento S.A.',
    label: '301',
    value: '301 - BPP IP S.A.'
  },
  {
    ispb: '13486793',
    name: 'BRL TRUST DTVM SA',
    code: 173,
    fullName: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
    label: '173',
    value: '173 - BRL TRUST DTVM SA'
  },
  {
    ispb: '13673855',
    name: 'FRAM CAPITAL DTVM S.A.',
    code: 331,
    fullName: 'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
    label: '331',
    value: '331 - FRAM CAPITAL DTVM S.A.'
  },
  {
    ispb: '13720915',
    name: 'BCO WESTERN UNION',
    code: 119,
    fullName: 'Banco Western Union do Brasil S.A.',
    label: '119',
    value: '119 - BCO WESTERN UNION'
  },
  {
    ispb: '13884775',
    name: 'HUB PAGAMENTOS',
    code: 396,
    fullName: 'HUB PAGAMENTOS S.A',
    label: '396',
    value: '396 - HUB PAGAMENTOS'
  },
  {
    ispb: '14190547',
    name: 'CAMBIONET CC LTDA',
    code: 309,
    fullName: 'CAMBIONET CORRETORA DE CÂMBIO LTDA.',
    label: '309',
    value: '309 - CAMBIONET CC LTDA'
  },
  {
    ispb: '14388334',
    name: 'PARANA BCO S.A.',
    code: 254,
    fullName: 'PARANÁ BANCO S.A.',
    label: '254',
    value: '254 - PARANA BCO S.A.'
  },
  {
    ispb: '14511781',
    name: 'BARI CIA HIPOTECÁRIA',
    code: 268,
    fullName: 'BARI COMPANHIA HIPOTECÁRIA',
    label: '268',
    value: '268 - BARI CIA HIPOTECÁRIA'
  },
  {
    ispb: '15111975',
    name: 'IUGU SERVICOS NA INTERNET S/A',
    code: 401,
    fullName: 'IUGU SERVICOS NA INTERNET S/A',
    label: '401',
    value: '401 - IUGU SERVICOS NA INTERNET S/A'
  },
  {
    ispb: '15114366',
    name: 'BCO BOCOM BBM S.A.',
    code: 107,
    fullName: 'Banco Bocom BBM S.A.',
    label: '107',
    value: '107 - BCO BOCOM BBM S.A.'
  },
  {
    ispb: '15173776',
    name: 'BCO CAPITAL S.A.',
    code: 412,
    fullName: 'BANCO CAPITAL S.A.',
    label: '412',
    value: '412 - BCO CAPITAL S.A.'
  },
  {
    ispb: '15357060',
    name: 'BCO WOORI BANK DO BRASIL S.A.',
    code: 124,
    fullName: 'Banco Woori Bank do Brasil S.A.',
    label: '124',
    value: '124 - BCO WOORI BANK DO BRASIL S.A.'
  },
  {
    ispb: '15581638',
    name: 'FACTA S.A. CFI',
    code: 149,
    fullName: 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
    label: '149',
    value: '149 - FACTA S.A. CFI'
  },
  {
    ispb: '16501555',
    name: 'STONE PAGAMENTOS S.A.',
    code: 197,
    fullName: 'Stone Pagamentos S.A.',
    label: '197',
    value: '197 - STONE PAGAMENTOS S.A.'
  },
  {
    ispb: '16927221',
    name: 'AMAZÔNIA CC LTDA.',
    code: 313,
    fullName: 'AMAZÔNIA CORRETORA DE CÂMBIO LTDA.',
    label: '313',
    value: '313 - AMAZÔNIA CC LTDA.'
  },
  {
    ispb: '16944141',
    name: 'BROKER BRASIL CC LTDA.',
    code: 142,
    fullName: 'Broker Brasil Corretora de Câmbio Ltda.',
    label: '142',
    value: '142 - BROKER BRASIL CC LTDA.'
  },
  {
    ispb: '17184037',
    name: 'BCO MERCANTIL DO BRASIL S.A.',
    code: 389,
    fullName: 'Banco Mercantil do Brasil S.A.',
    label: '389',
    value: '389 - BCO MERCANTIL DO BRASIL S.A.'
  },
  {
    ispb: '17298092',
    name: 'BCO ITAÚ BBA S.A.',
    code: 184,
    fullName: 'Banco Itaú BBA S.A.',
    label: '184',
    value: '184 - BCO ITAÚ BBA S.A.'
  },
  {
    ispb: '17351180',
    name: 'BCO TRIANGULO S.A.',
    code: 634,
    fullName: 'BANCO TRIANGULO S.A.',
    label: '634',
    value: '634 - BCO TRIANGULO S.A.'
  },
  {
    ispb: '17352220',
    name: 'SENSO CCVM S.A.',
    code: 545,
    fullName: 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
    label: '545',
    value: '545 - SENSO CCVM S.A.'
  },
  {
    ispb: '17453575',
    name: 'ICBC DO BRASIL BM S.A.',
    code: 132,
    fullName: 'ICBC do Brasil Banco Múltiplo S.A.',
    label: '132',
    value: '132 - ICBC DO BRASIL BM S.A.'
  },
  {
    ispb: '17772370',
    name: 'VIPS CC LTDA.',
    code: 298,
    fullName: "Vip's Corretora de Câmbio Ltda.",
    label: '298',
    value: '298 - VIPS CC LTDA.'
  },
  {
    ispb: '17826860',
    name: 'BMS SCD S.A.',
    code: 377,
    fullName: 'BMS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '377',
    value: '377 - BMS SCD S.A.'
  },
  {
    ispb: '18188384',
    name: 'CREFAZ SCMEPP LTDA',
    code: 321,
    fullName: 'CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
    label: '321',
    value: '321 - CREFAZ SCMEPP LTDA'
  },
  {
    ispb: '18236120',
    name: 'NU PAGAMENTOS S.A.',
    code: 260,
    fullName: 'Nu Pagamentos S.A.',
    label: '260',
    value: '260 - NU PAGAMENTOS S.A.'
  },
  {
    ispb: '18520834',
    name: 'UBS BRASIL BI S.A.',
    code: 129,
    fullName: 'UBS Brasil Banco de Investimento S.A.',
    label: '129',
    value: '129 - UBS BRASIL BI S.A.'
  },
  {
    ispb: '19307785',
    name: 'MS BANK S.A. BCO DE CÂMBIO',
    code: 128,
    fullName: 'MS Bank S.A. Banco de Câmbio',
    label: '128',
    value: '128 - MS BANK S.A. BCO DE CÂMBIO'
  },
  {
    ispb: '19324634',
    name: 'LAMARA SCD S.A.',
    code: 416,
    fullName: 'LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '416',
    value: '416 - LAMARA SCD S.A.'
  },
  {
    ispb: '20155248',
    name: 'PARMETAL DTVM LTDA',
    code: 194,
    fullName: 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    label: '194',
    value: '194 - PARMETAL DTVM LTDA'
  },
  {
    ispb: '21018182',
    name: 'JUNO',
    code: 383,
    fullName: 'BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.',
    label: '383',
    value: '383 - JUNO'
  },
  {
    ispb: '21332862',
    name: 'CARTOS SCD S.A.',
    code: 324,
    fullName: 'CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '324',
    value: '324 - CARTOS SCD S.A.'
  },
  {
    ispb: '22610500',
    name: 'VORTX DTVM LTDA.',
    code: 310,
    fullName: 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    label: '310',
    value: '310 - VORTX DTVM LTDA.'
  },
  {
    ispb: '22896431',
    name: 'PICPAY',
    code: 380,
    fullName: 'PICPAY SERVICOS S.A.',
    label: '380',
    value: '380 - PICPAY'
  },
  {
    ispb: '23522214',
    name: 'COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO',
    code: 163,
    fullName: 'Commerzbank Brasil S.A. - Banco Múltiplo',
    label: '163',
    value: '163 - COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO'
  },
  {
    ispb: '23862762',
    name: 'WILL FINANCEIRA S.A.CFI',
    code: 280,
    fullName: '"WILL FINANCEIRA S.A. CRÉDITO',
    label: '280',
    value: '280 - WILL FINANCEIRA S.A.CFI'
  },
  {
    ispb: '24074692',
    name: 'GUITTA CC LTDA',
    code: 146,
    fullName: 'GUITTA CORRETORA DE CAMBIO LTDA.',
    label: '146',
    value: '146 - GUITTA CC LTDA'
  },
  {
    ispb: '24537861',
    name: 'FFA SCMEPP LTDA.',
    code: 343,
    fullName: 'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    label: '343',
    value: '343 - FFA SCMEPP LTDA.'
  },
  {
    ispb: '26563270',
    name: 'CCR DE PRIMAVERA DO LESTE',
    code: 279,
    fullName: 'COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE',
    label: '279',
    value: '279 - CCR DE PRIMAVERA DO LESTE'
  },
  {
    ispb: '27098060',
    name: 'BANCO DIGIO',
    code: 335,
    fullName: 'Banco Digio S.A.',
    label: '335',
    value: '335 - BANCO DIGIO'
  },
  {
    ispb: '27214112',
    name: 'AL5 S.A. CFI',
    code: 349,
    fullName: '"AL5 S.A. CRÉDITO',
    label: '349',
    value: '349 - AL5 S.A. CFI'
  },
  {
    ispb: '27302181',
    name: 'CRED-UFES',
    code: 427,
    fullName: 'COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO',
    label: '427',
    value: '427 - CRED-UFES'
  },
  {
    ispb: '27351731',
    name: 'REALIZE CFI S.A.',
    code: 374,
    fullName: '"REALIZE CRÉDITO',
    label: '374',
    value: '374 - REALIZE CFI S.A.'
  },
  {
    ispb: '27652684',
    name: 'GENIAL INVESTIMENTOS CVM S.A.',
    code: 278,
    fullName: 'Genial Investimentos Corretora de Valores Mobiliários S.A.',
    label: '278',
    value: '278 - GENIAL INVESTIMENTOS CVM S.A.'
  },
  {
    ispb: '27842177',
    name: 'IB CCTVM S.A.',
    code: 271,
    fullName: '"IB Corretora de Câmbio',
    label: '271',
    value: '271 - IB CCTVM S.A.'
  },
  {
    ispb: '28127603',
    name: 'BCO BANESTES S.A.',
    code: 21,
    fullName: 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO',
    label: '021',
    value: '021 - BCO BANESTES S.A.'
  },
  {
    ispb: '28195667',
    name: 'BCO ABC BRASIL S.A.',
    code: 246,
    fullName: 'Banco ABC Brasil S.A.',
    label: '246',
    value: '246 - BCO ABC BRASIL S.A.'
  },
  {
    ispb: '28650236',
    name: 'BS2 DTVM S.A.',
    code: 292,
    fullName: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.',
    label: '292',
    value: '292 - BS2 DTVM S.A.'
  },
  {
    ispb: '29030467',
    name: 'SCOTIABANK BRASIL',
    code: 751,
    fullName: 'Scotiabank Brasil S.A. Banco Múltiplo',
    label: '751',
    value: '751 - SCOTIABANK BRASIL'
  },
  {
    ispb: '29162769',
    name: 'TORO CTVM LTDA',
    code: 352,
    fullName: 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    label: '352',
    value: '352 - TORO CTVM LTDA'
  },
  {
    ispb: '30306294',
    name: 'BANCO BTG PACTUAL S.A.',
    code: 208,
    fullName: 'Banco BTG Pactual S.A.',
    label: '208',
    value: '208 - BANCO BTG PACTUAL S.A.'
  },
  {
    ispb: '30680829',
    name: 'NU FINANCEIRA S.A. CFI',
    code: 386,
    fullName: '"NU FINANCEIRA S.A. - Sociedade de Crédito',
    label: '386',
    value: '386 - NU FINANCEIRA S.A. CFI'
  },
  {
    ispb: '30723886',
    name: 'BCO MODAL S.A.',
    code: 746,
    fullName: 'Banco Modal S.A.',
    label: '746',
    value: '746 - BCO MODAL S.A.'
  },
  {
    ispb: '31597552',
    name: 'BCO CLASSICO S.A.',
    code: 241,
    fullName: 'BANCO CLASSICO S.A.',
    label: '241',
    value: '241 - BCO CLASSICO S.A.'
  },
  {
    ispb: '31749596',
    name: 'IDEAL CTVM S.A.',
    code: 398,
    fullName: 'IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    label: '398',
    value: '398 - IDEAL CTVM S.A.'
  },
  {
    ispb: '31872495',
    name: 'BCO C6 S.A.',
    code: 336,
    fullName: 'Banco C6 S.A.',
    label: '336',
    value: '336 - BCO C6 S.A.'
  },
  {
    ispb: '31880826',
    name: 'BCO GUANABARA S.A.',
    code: 612,
    fullName: 'Banco Guanabara S.A.',
    label: '612',
    value: '612 - BCO GUANABARA S.A.'
  },
  {
    ispb: '31895683',
    name: 'BCO INDUSTRIAL DO BRASIL S.A.',
    code: 604,
    fullName: 'Banco Industrial do Brasil S.A.',
    label: '604',
    value: '604 - BCO INDUSTRIAL DO BRASIL S.A.'
  },
  {
    ispb: '32062580',
    name: 'BCO CREDIT SUISSE S.A.',
    code: 505,
    fullName: 'Banco Credit Suisse (Brasil) S.A.',
    label: '505',
    value: '505 - BCO CREDIT SUISSE S.A.'
  },
  {
    ispb: '32402502',
    name: 'QI SCD S.A.',
    code: 329,
    fullName: 'QI Sociedade de Crédito Direto S.A.',
    label: '329',
    value: '329 - QI SCD S.A.'
  },
  {
    ispb: '32648370',
    name: 'FAIR CC S.A.',
    code: 196,
    fullName: 'FAIR CORRETORA DE CAMBIO S.A.',
    label: '196',
    value: '196 - FAIR CC S.A.'
  },
  {
    ispb: '32997490',
    name: 'CREDITAS SCD',
    code: 342,
    fullName: 'Creditas Sociedade de Crédito Direto S.A.',
    label: '342',
    value: '342 - CREDITAS SCD'
  },
  {
    ispb: '33042151',
    name: 'BCO LA NACION ARGENTINA',
    code: 300,
    fullName: 'Banco de la Nacion Argentina',
    label: '300',
    value: '300 - BCO LA NACION ARGENTINA'
  },
  {
    ispb: '33042953',
    name: 'CITIBANK N.A.',
    code: 477,
    fullName: 'Citibank N.A.',
    label: '477',
    value: '477 - CITIBANK N.A.'
  },
  {
    ispb: '33132044',
    name: 'BCO CEDULA S.A.',
    code: 266,
    fullName: 'BANCO CEDULA S.A.',
    label: '266',
    value: '266 - BCO CEDULA S.A.'
  },
  {
    ispb: '33147315',
    name: 'BCO BRADESCO BERJ S.A.',
    code: 122,
    fullName: 'Banco Bradesco BERJ S.A.',
    label: '122',
    value: '122 - BCO BRADESCO BERJ S.A.'
  },
  {
    ispb: '33172537',
    name: 'BCO J.P. MORGAN S.A.',
    code: 376,
    fullName: 'BANCO J.P. MORGAN S.A.',
    label: '376',
    value: '376 - BCO J.P. MORGAN S.A.'
  },
  {
    ispb: '33264668',
    name: 'BCO XP S.A.',
    code: 348,
    fullName: 'Banco XP S.A.',
    label: '348',
    value: '348 - BCO XP S.A.'
  },
  {
    ispb: '33466988',
    name: 'BCO CAIXA GERAL BRASIL S.A.',
    code: 473,
    fullName: 'Banco Caixa Geral - Brasil S.A.',
    label: '473',
    value: '473 - BCO CAIXA GERAL BRASIL S.A.'
  },
  {
    ispb: '33479023',
    name: 'BCO CITIBANK S.A.',
    code: 745,
    fullName: 'Banco Citibank S.A.',
    label: '745',
    value: '745 - BCO CITIBANK S.A.'
  },
  {
    ispb: '33603457',
    name: 'BCO RODOBENS S.A.',
    code: 120,
    fullName: 'BANCO RODOBENS S.A.',
    label: '120',
    value: '120 - BCO RODOBENS S.A.'
  },
  {
    ispb: '33644196',
    name: 'BCO FATOR S.A.',
    code: 265,
    fullName: 'Banco Fator S.A.',
    label: '265',
    value: '265 - BCO FATOR S.A.'
  },
  {
    ispb: '33657248',
    name: 'BNDES',
    code: 7,
    fullName: 'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL',
    label: '007',
    value: '007 - BNDES'
  },
  {
    ispb: '33775974',
    name: 'ATIVA S.A. INVESTIMENTOS CCTVM',
    code: 188,
    fullName: '"ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS',
    label: '188',
    value: '188 - ATIVA S.A. INVESTIMENTOS CCTVM'
  },
  {
    ispb: '33862244',
    name: 'BGC LIQUIDEZ DTVM LTDA',
    code: 134,
    fullName: 'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    label: '134',
    value: '134 - BGC LIQUIDEZ DTVM LTDA'
  },
  {
    ispb: '33885724',
    name: 'BANCO ITAÚ CONSIGNADO S.A.',
    code: 29,
    fullName: 'Banco Itaú Consignado S.A.',
    label: '029',
    value: '029 - BANCO ITAÚ CONSIGNADO S.A.'
  },
  {
    ispb: '33923798',
    name: 'BCO MÁXIMA S.A.',
    code: 243,
    fullName: 'Banco Máxima S.A.',
    label: '243',
    value: '243 - BCO MÁXIMA S.A.'
  },
  {
    ispb: '34088029',
    name: 'LISTO SCD S.A.',
    code: 397,
    fullName: 'LISTO SOCIEDADE DE CREDITO DIRETO S.A.',
    label: '397',
    value: '397 - LISTO SCD S.A.'
  },
  {
    ispb: '34111187',
    name: 'HAITONG BI DO BRASIL S.A.',
    code: 78,
    fullName: 'Haitong Banco de Investimento do Brasil S.A.',
    label: '078',
    value: '078 - HAITONG BI DO BRASIL S.A.'
  },
  {
    ispb: '34335592',
    name: 'ÓTIMO SCD S.A.',
    code: 355,
    fullName: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '355',
    value: '355 - ÓTIMO SCD S.A.'
  },
  {
    ispb: '34711571',
    name: 'VITREO DTVM S.A.',
    code: 367,
    fullName: 'VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    label: '367',
    value: '367 - VITREO DTVM S.A.'
  },
  {
    ispb: '35977097',
    name: 'UP.P SEP S.A.',
    code: 373,
    fullName: 'UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
    label: '373',
    value: '373 - UP.P SEP S.A.'
  },
  {
    ispb: '36113876',
    name: 'OLIVEIRA TRUST DTVM S.A.',
    code: 111,
    fullName: 'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
    label: '111',
    value: '111 - OLIVEIRA TRUST DTVM S.A.'
  },
  {
    ispb: '36586946',
    name: 'BONUSPAGO SCD S.A.',
    code: 408,
    fullName: 'BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '408',
    value: '408 - BONUSPAGO SCD S.A.'
  },
  {
    ispb: '36947229',
    name: 'COBUCCIO SCD S.A.',
    code: 402,
    fullName: 'COBUCCIO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '402',
    value: '402 - COBUCCIO SCD S.A.'
  },
  {
    ispb: '37241230',
    name: 'SUMUP SCD S.A.',
    code: 404,
    fullName: 'SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '404',
    value: '404 - SUMUP SCD S.A.'
  },
  {
    ispb: '37526080',
    name: 'WORK SCD S.A.',
    code: 414,
    fullName: 'WORK SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '414',
    value: '414 - WORK SCD S.A.'
  },
  {
    ispb: '37715993',
    name: 'ACCREDITO SCD S.A.',
    code: 406,
    fullName: 'ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '406',
    value: '406 - ACCREDITO SCD S.A.'
  },
  {
    ispb: '37880206',
    name: 'CORA SCD S.A.',
    code: 403,
    fullName: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '403',
    value: '403 - CORA SCD S.A.'
  },
  {
    ispb: '38129006',
    name: 'NUMBRS SCD S.A.',
    code: 419,
    fullName: 'NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '419',
    value: '419 - NUMBRS SCD S.A.'
  },
  {
    ispb: '39664698',
    name: 'CRED-SYSTEM SCD S.A.',
    code: 428,
    fullName: 'CRED-SYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.',
    label: '428',
    value: '428 - CRED-SYSTEM SCD S.A.'
  },
  {
    ispb: '40303299',
    name: 'PORTOPAR DTVM LTDA',
    code: 306,
    fullName: 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    label: '306',
    value: '306 - PORTOPAR DTVM LTDA'
  },
  {
    ispb: '42272526',
    name: 'BNY MELLON BCO S.A.',
    code: 17,
    fullName: 'BNY Mellon Banco S.A.',
    label: '017',
    value: '017 - BNY MELLON BCO S.A.'
  },
  {
    ispb: '43180355',
    name: 'PEFISA S.A. - CFI',
    code: 174,
    fullName: '"PEFISA S.A. - CRÉDITO',
    label: '174',
    value: '174 - PEFISA S.A. - CFI'
  },
  {
    ispb: '44189447',
    name: 'BCO LA PROVINCIA B AIRES BCE',
    code: 495,
    fullName: 'Banco de La Provincia de Buenos Aires',
    label: '495',
    value: '495 - BCO LA PROVINCIA B AIRES BCE'
  },
  {
    ispb: '45246410',
    name: 'BANCO GENIAL',
    code: 125,
    fullName: 'BANCO GENIAL S.A.',
    label: '125',
    value: '125 - BANCO GENIAL'
  },
  {
    ispb: '46518205',
    name: 'JPMORGAN CHASE BANK',
    code: 488,
    fullName: '"JPMorgan Chase Bank',
    label: '488',
    value: '488 - JPMORGAN CHASE BANK'
  },
  {
    ispb: '48795256',
    name: 'BCO ANDBANK S.A.',
    code: 65,
    fullName: 'Banco AndBank (Brasil) S.A.',
    label: '065',
    value: '065 - BCO ANDBANK S.A.'
  },
  {
    ispb: '49336860',
    name: 'ING BANK N.V.',
    code: 492,
    fullName: 'ING Bank N.V.',
    label: '492',
    value: '492 - ING BANK N.V.'
  },
  {
    ispb: '50579044',
    name: 'LEVYCAM CCV LTDA',
    code: 145,
    fullName: 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.',
    label: '145',
    value: '145 - LEVYCAM CCV LTDA'
  },
  {
    ispb: '52904364',
    name: 'NECTON INVESTIMENTOS S.A CVM',
    code: 354,
    fullName: 'NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES',
    label: '354',
    value: '354 - NECTON INVESTIMENTOS S.A CVM'
  },
  {
    ispb: '52937216',
    name: 'BEXS CC S.A.',
    code: 253,
    fullName: 'Bexs Corretora de Câmbio S/A',
    label: '253',
    value: '253 - BEXS CC S.A.'
  },
  {
    ispb: '53518684',
    name: 'BCO HSBC S.A.',
    code: 269,
    fullName: 'BANCO HSBC S.A.',
    label: '269',
    value: '269 - BCO HSBC S.A.'
  },
  {
    ispb: '54403563',
    name: 'BCO ARBI S.A.',
    code: 213,
    fullName: 'Banco Arbi S.A.',
    label: '213',
    value: '213 - BCO ARBI S.A.'
  },
  {
    ispb: '55230916',
    name: 'INTESA SANPAOLO BRASIL S.A. BM',
    code: 139,
    fullName: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
    label: '139',
    value: '139 - INTESA SANPAOLO BRASIL S.A. BM'
  },
  {
    ispb: '57839805',
    name: 'BCO TRICURY S.A.',
    code: 18,
    fullName: 'Banco Tricury S.A.',
    label: '018',
    value: '018 - BCO TRICURY S.A.'
  },
  {
    ispb: '58160789',
    name: 'BCO SAFRA S.A.',
    code: 422,
    fullName: 'Banco Safra S.A.',
    label: '422',
    value: '422 - BCO SAFRA S.A.'
  },
  {
    ispb: '58497702',
    name: 'SMARTBANK',
    code: 630,
    fullName: 'Banco Smartbank S.A.',
    label: '630',
    value: '630 - SMARTBANK'
  },
  {
    ispb: '58616418',
    name: 'BCO FIBRA S.A.',
    code: 224,
    fullName: 'Banco Fibra S.A.',
    label: '224',
    value: '224 - BCO FIBRA S.A.'
  },
  {
    ispb: '59109165',
    name: 'BCO VOLKSWAGEN S.A',
    code: 393,
    fullName: 'Banco Volkswagen S.A.',
    label: '393',
    value: '393 - BCO VOLKSWAGEN S.A'
  },
  {
    ispb: '59118133',
    name: 'BCO LUSO BRASILEIRO S.A.',
    code: 600,
    fullName: 'Banco Luso Brasileiro S.A.',
    label: '600',
    value: '600 - BCO LUSO BRASILEIRO S.A.'
  },
  {
    ispb: '59274605',
    name: 'BCO GM S.A.',
    code: 390,
    fullName: 'BANCO GM S.A.',
    label: '390',
    value: '390 - BCO GM S.A.'
  },
  {
    ispb: '59285411',
    name: 'BANCO PAN',
    code: 623,
    fullName: 'Banco Pan S.A.',
    label: '623',
    value: '623 - BANCO PAN'
  },
  {
    ispb: '59588111',
    name: 'BCO VOTORANTIM S.A.',
    code: 655,
    fullName: 'Banco Votorantim S.A.',
    label: '655',
    value: '655 - BCO VOTORANTIM S.A.'
  },
  {
    ispb: '60394079',
    name: 'BCO ITAUBANK S.A.',
    code: 479,
    fullName: 'Banco ItauBank S.A.',
    label: '479',
    value: '479 - BCO ITAUBANK S.A.'
  },
  {
    ispb: '60498557',
    name: 'BCO MUFG BRASIL S.A.',
    code: 456,
    fullName: 'Banco MUFG Brasil S.A.',
    label: '456',
    value: '456 - BCO MUFG BRASIL S.A.'
  },
  {
    ispb: '60518222',
    name: 'BCO SUMITOMO MITSUI BRASIL S.A.',
    code: 464,
    fullName: 'Banco Sumitomo Mitsui Brasileiro S.A.',
    label: '464',
    value: '464 - BCO SUMITOMO MITSUI BRASIL S.A.'
  },
  {
    ispb: '60701190',
    name: 'ITAÚ UNIBANCO S.A.',
    code: 341,
    fullName: 'ITAÚ UNIBANCO S.A.',
    label: '341',
    value: '341 - ITAÚ UNIBANCO S.A.'
  },
  {
    ispb: '60746948',
    name: 'BCO BRADESCO S.A.',
    code: 237,
    fullName: 'Banco Bradesco S.A.',
    label: '237',
    value: '237 - BCO BRADESCO S.A.'
  },
  {
    ispb: '60814191',
    name: 'BCO MERCEDES-BENZ S.A.',
    code: 381,
    fullName: 'BANCO MERCEDES-BENZ DO BRASIL S.A.',
    label: '381',
    value: '381 - BCO MERCEDES-BENZ S.A.'
  },
  {
    ispb: '60850229',
    name: 'OMNI BANCO S.A.',
    code: 613,
    fullName: 'Omni Banco S.A.',
    label: '613',
    value: '613 - OMNI BANCO S.A.'
  },
  {
    ispb: '60872504',
    name: 'ITAÚ UNIBANCO HOLDING S.A.',
    code: 652,
    fullName: 'Itaú Unibanco Holding S.A.',
    label: '652',
    value: '652 - ITAÚ UNIBANCO HOLDING S.A.'
  },
  {
    ispb: '60889128',
    name: 'BCO SOFISA S.A.',
    code: 637,
    fullName: 'BANCO SOFISA S.A.',
    label: '637',
    value: '637 - BCO SOFISA S.A.'
  },
  {
    ispb: '61024352',
    name: 'BCO INDUSVAL S.A.',
    code: 653,
    fullName: 'BANCO INDUSVAL S.A.',
    label: '653',
    value: '653 - BCO INDUSVAL S.A.'
  },
  {
    ispb: '61033106',
    name: 'BCO CREFISA S.A.',
    code: 69,
    fullName: 'Banco Crefisa S.A.',
    label: '069',
    value: '069 - BCO CREFISA S.A.'
  },
  {
    ispb: '61088183',
    name: 'BCO MIZUHO S.A.',
    code: 370,
    fullName: 'Banco Mizuho do Brasil S.A.',
    label: '370',
    value: '370 - BCO MIZUHO S.A.'
  },
  {
    ispb: '61182408',
    name: 'BANCO INVESTCRED UNIBANCO S.A.',
    code: 249,
    fullName: 'Banco Investcred Unibanco S.A.',
    label: '249',
    value: '249 - BANCO INVESTCRED UNIBANCO S.A.'
  },
  {
    ispb: '61186680',
    name: 'BCO BMG S.A.',
    code: 318,
    fullName: 'Banco BMG S.A.',
    label: '318',
    value: '318 - BCO BMG S.A.'
  },
  {
    ispb: '61348538',
    name: 'BCO C6 CONSIG',
    code: 626,
    fullName: 'BANCO C6 CONSIGNADO S.A.',
    label: '626',
    value: '626 - BCO C6 CONSIG'
  },
  {
    ispb: '61444949',
    name: 'SAGITUR CC LTDA',
    code: 270,
    fullName: 'Sagitur Corretora de Câmbio Ltda.',
    label: '270',
    value: '270 - SAGITUR CC LTDA'
  },
  {
    ispb: '61533584',
    name: 'BCO SOCIETE GENERALE BRASIL',
    code: 366,
    fullName: 'BANCO SOCIETE GENERALE BRASIL S.A.',
    label: '366',
    value: '366 - BCO SOCIETE GENERALE BRASIL'
  },
  {
    ispb: '61723847',
    name: 'MAGLIANO S.A. CCVM',
    code: 113,
    fullName: 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios',
    label: '113',
    value: '113 - MAGLIANO S.A. CCVM'
  },
  {
    ispb: '61747085',
    name: 'TULLETT PREBON BRASIL CVC LTDA',
    code: 131,
    fullName: 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA',
    label: '131',
    value: '131 - TULLETT PREBON BRASIL CVC LTDA'
  },
  {
    ispb: '61809182',
    name: 'C.SUISSE HEDGING-GRIFFO CV S/A',
    code: 11,
    fullName: 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A',
    label: '011',
    value: '011 - C.SUISSE HEDGING-GRIFFO CV S/A'
  },
  {
    ispb: '61820817',
    name: 'BCO PAULISTA S.A.',
    code: 611,
    fullName: 'Banco Paulista S.A.',
    label: '611',
    value: '611 - BCO PAULISTA S.A.'
  },
  {
    ispb: '62073200',
    name: 'BOFA MERRILL LYNCH BM S.A.',
    code: 755,
    fullName: 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
    label: '755',
    value: '755 - BOFA MERRILL LYNCH BM S.A.'
  },
  {
    ispb: '62109566',
    name: 'CREDISAN CC',
    code: 89,
    fullName: 'CREDISAN COOPERATIVA DE CRÉDITO',
    label: '089',
    value: '089 - CREDISAN CC'
  },
  {
    ispb: '62144175',
    name: 'BCO PINE S.A.',
    code: 643,
    fullName: 'Banco Pine S.A.',
    label: '643',
    value: '643 - BCO PINE S.A.'
  },
  {
    ispb: '62169875',
    name: 'EASYNVEST - TÍTULO CV SA',
    code: 140,
    fullName: 'Easynvest - Título Corretora de Valores SA',
    label: '140',
    value: '140 - EASYNVEST - TÍTULO CV SA'
  },
  {
    ispb: '62232889',
    name: 'BCO DAYCOVAL S.A',
    code: 707,
    fullName: 'Banco Daycoval S.A.',
    label: '707',
    value: '707 - BCO DAYCOVAL S.A'
  },
  {
    ispb: '62237649',
    name: 'CAROL DTVM LTDA.',
    code: 288,
    fullName: 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    label: '288',
    value: '288 - CAROL DTVM LTDA.'
  },
  {
    ispb: '62285390',
    name: 'SINGULARE CTVM S.A.',
    code: 363,
    fullName: 'SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    label: '363',
    value: '363 - SINGULARE CTVM S.A.'
  },
  {
    ispb: '62287735',
    name: 'RENASCENCA DTVM LTDA',
    code: 101,
    fullName: 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    label: '101',
    value: '101 - RENASCENCA DTVM LTDA'
  },
  {
    ispb: '62331228',
    name: 'DEUTSCHE BANK S.A.BCO ALEMAO',
    code: 487,
    fullName: 'DEUTSCHE BANK S.A. - BANCO ALEMAO',
    label: '487',
    value: '487 - DEUTSCHE BANK S.A.BCO ALEMAO'
  },
  {
    ispb: '62421979',
    name: 'BANCO CIFRA',
    code: 233,
    fullName: 'Banco Cifra S.A.',
    label: '233',
    value: '233 - BANCO CIFRA'
  },
  {
    ispb: '65913436',
    name: 'GUIDE',
    code: 177,
    fullName: 'Guide Investimentos S.A. Corretora de Valores',
    label: '177',
    value: '177 - GUIDE'
  },
  {
    ispb: '67030395',
    name: 'PLANNER TRUSTEE DTVM LTDA',
    code: 438,
    fullName: 'PLANNER TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    label: '438',
    value: '438 - PLANNER TRUSTEE DTVM LTDA'
  },
  {
    ispb: '68757681',
    name: 'SIMPAUL',
    code: 365,
    fullName: 'SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS  S.A.',
    label: '365',
    value: '365 - SIMPAUL'
  },
  {
    ispb: '68900810',
    name: 'BCO RENDIMENTO S.A.',
    code: 633,
    fullName: 'Banco Rendimento S.A.',
    label: '633',
    value: '633 - BCO RENDIMENTO S.A.'
  },
  {
    ispb: '71027866',
    name: 'BCO BS2 S.A.',
    code: 218,
    fullName: 'Banco BS2 S.A.',
    label: '218',
    value: '218 - BCO BS2 S.A.'
  },
  {
    ispb: '71590442',
    name: 'LASTRO RDV DTVM LTDA',
    code: 293,
    fullName: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
    label: '293',
    value: '293 - LASTRO RDV DTVM LTDA'
  },
  {
    ispb: '71677850',
    name: 'FRENTE CC LTDA.',
    code: 285,
    fullName: 'Frente Corretora de Câmbio Ltda.',
    label: '285',
    value: '285 - FRENTE CC LTDA.'
  },
  {
    ispb: '73622748',
    name: 'B&T CC LTDA.',
    code: 80,
    fullName: 'B&T CORRETORA DE CAMBIO LTDA.',
    label: '080',
    value: '080 - B&T CC LTDA.'
  },
  {
    ispb: '74828799',
    name: 'NOVO BCO CONTINENTAL S.A. - BM',
    code: 753,
    fullName: 'Novo Banco Continental S.A. - Banco Múltiplo',
    label: '753',
    value: '753 - NOVO BCO CONTINENTAL S.A. - BM'
  },
  {
    ispb: '75647891',
    name: 'BCO CRÉDIT AGRICOLE BR S.A.',
    code: 222,
    fullName: 'BANCO CRÉDIT AGRICOLE BRASIL S.A.',
    label: '222',
    value: '222 - BCO CRÉDIT AGRICOLE BR S.A.'
  },
  {
    ispb: '76461557',
    name: 'CCR COOPAVEL',
    code: 281,
    fullName: 'Cooperativa de Crédito Rural Coopavel',
    label: '281',
    value: '281 - CCR COOPAVEL'
  },
  {
    ispb: '76543115',
    name: 'BANCO SISTEMA',
    code: 754,
    fullName: 'Banco Sistema S.A.',
    label: '754',
    value: '754 - BANCO SISTEMA'
  },
  {
    ispb: '76641497',
    name: 'DOURADA CORRETORA',
    code: 311,
    fullName: 'DOURADA CORRETORA DE CÂMBIO LTDA.',
    label: '311',
    value: '311 - DOURADA CORRETORA'
  },
  {
    ispb: '78157146',
    name: 'CREDIALIANÇA CCR',
    code: 98,
    fullName: 'Credialiança Cooperativa de Crédito Rural',
    label: '098',
    value: '098 - CREDIALIANÇA CCR'
  },
  {
    ispb: '78626983',
    name: 'BCO VR S.A.',
    code: 610,
    fullName: 'Banco VR S.A.',
    label: '610',
    value: '610 - BCO VR S.A.'
  },
  {
    ispb: '78632767',
    name: 'BCO OURINVEST S.A.',
    code: 712,
    fullName: 'Banco Ourinvest S.A.',
    label: '712',
    value: '712 - BCO OURINVEST S.A.'
  },
  {
    ispb: '80271455',
    name: 'BCO RNX S.A.',
    code: 720,
    fullName: 'BANCO RNX S.A.',
    label: '720',
    value: '720 - BCO RNX S.A.'
  },
  {
    ispb: '81723108',
    name: 'CREDICOAMO',
    code: 10,
    fullName: 'CREDICOAMO CREDITO RURAL COOPERATIVA',
    label: '010',
    value: '010 - CREDICOAMO'
  },
  {
    ispb: '89960090',
    name: 'RB INVESTIMENTOS DTVM LTDA.',
    code: 283,
    fullName: 'RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA',
    label: '283',
    value: '283 - RB INVESTIMENTOS DTVM LTDA.'
  },
  {
    ispb: '90400888',
    name: 'BCO SANTANDER (BRASIL) S.A.',
    code: 33,
    fullName: 'BANCO SANTANDER (BRASIL) S.A.',
    label: '033',
    value: '033 - BCO SANTANDER (BRASIL) S.A.'
  },
  {
    ispb: '91884981',
    name: 'BANCO JOHN DEERE S.A.',
    code: 217,
    fullName: 'Banco John Deere S.A.',
    label: '217',
    value: '217 - BANCO JOHN DEERE S.A.'
  },
  {
    ispb: '92702067',
    name: 'BCO DO ESTADO DO RS S.A.',
    code: 41,
    fullName: 'Banco do Estado do Rio Grande do Sul S.A.',
    label: '041',
    value: '041 - BCO DO ESTADO DO RS S.A.'
  },
  {
    ispb: '92856905',
    name: 'ADVANCED CC LTDA',
    code: 117,
    fullName: 'ADVANCED CORRETORA DE CÂMBIO LTDA',
    label: '117',
    value: '117 - ADVANCED CC LTDA'
  },
  {
    ispb: '92874270',
    name: 'BCO DIGIMAIS S.A.',
    code: 654,
    fullName: 'BANCO DIGIMAIS S.A.',
    label: '654',
    value: '654 - BCO DIGIMAIS S.A.'
  },
  {
    ispb: '92875780',
    name: 'WARREN CVMC LTDA',
    code: 371,
    fullName: 'WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.',
    label: '371',
    value: '371 - WARREN CVMC LTDA'
  },
  {
    ispb: '92894922',
    name: 'BANCO ORIGINAL',
    code: 212,
    fullName: 'Banco Original S.A.',
    label: '212',
    value: '212 - BANCO ORIGINAL'
  },
  {
    ispb: '94968518',
    name: 'DECYSEO CC LTDA.',
    code: 289,
    fullName: 'DECYSEO CORRETORA DE CAMBIO LTDA.',
    label: '289',
    value: '289 - DECYSEO CC LTDA.'
  },
  {
    ispb: '19540550',
    name: 'ASAAS IP S.A.',
    code: 461,
    fullName: 'ASAAS Gestão Financeira Instituição de Pagamento S.A.',
    label: '461',
    value: '461 - ASAAS IP S.A.'
  }, {
    ispb: '20855875',
    name: 'NEON PAGAMENTOS S.A. IP',
    code: 536,
    fullName: 'NEON PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
    label: '536',
    value: '536 - NEON PAGAMENTOS S.A. IP'
  },
];
