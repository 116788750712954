import { __ } from '../i18n';

export const relevantCountries = () => [
  {
    value: '+54',
    text: __('Argentina'),
    code: 'ar',
    flag: '🇦🇷'
  },
  {
    value: '+61',
    text: __('Australia'),
    code: 'au',
    flag: '🇦🇺'
  },
  {
    value: '+55',
    text: __('Brazil'),
    code: 'br',
    flag: '🇧🇷'
  },
  {
    value: '+1 ',
    text: __('Canada'),
    code: 'ca',
    flag: '🇨🇦'
  },
  {
    value: '+56',
    text: __('Chile'),
    code: 'cl',
    flag: '🇨🇱'
  },
  {
    value: '+86',
    text: __('China'),
    code: 'cn',
    flag: '🇨🇳'
  },
  {
    value: '+57',
    text: __('Colombia'),
    code: 'co',
    flag: '🇨🇴'
  },
  {
    value: '+358',
    text: __('Finland'),
    code: 'fi',
    flag: '🇫🇮'
  },
  {
    value: '+39',
    text: __('Italy'),
    code: 'it',
    flag: '🇮🇹'
  },
  {
    value: '+81',
    text: __('Japan'),
    code: 'jp',
    flag: '🇯🇵'
  },
  {
    value: '+47',
    text: __('Norway'),
    code: 'no',
    flag: '🇳🇴'
  },
  {
    value: '+595',
    text: __('Paraguay'),
    code: 'pa',
    flag: '🇵🇾'
  },
  {
    value: '+351',
    text: __('Portugal'),
    code: 'pt',
    flag: '🇵🇹'
  },
  {
    value: '+7',
    text: __('Russia'),
    code: 'ru',
    flag: '🇷🇺'
  },
  {
    value: '+46',
    text: __('Sweden'),
    code: 'se',
    flag: '🇸🇪'
  },
  {
    value: '+1',
    text: __('United States'),
    code: 'us',
    flag: '🇺🇸'
  },
];

export const allCountries = () => [
  {
    value: '+54',
    text: __('Argentina'),
    code: 'ar',
    flag: '🇦🇷'
  },
  {
    value: '+61',
    text: __('Australia'),
    code: 'au',
    flag: '🇦🇺'
  },
  {
    value: '+55',
    text: __('Brazil'),
    code: 'br',
    flag: '🇧🇷'
  },
  {
    value: '+1 ',
    text: __('Canada'),
    code: 'ca',
    flag: '🇨🇦'
  },
  {
    value: '+56',
    text: __('Chile'),
    code: 'cl',
    flag: '🇨🇱'
  },
  {
    value: '+86',
    text: __('China'),
    code: 'cn',
    flag: '🇨🇳'
  },
  {
    value: '+57',
    text: __('Colombia'),
    code: 'co',
    flag: '🇨🇴'
  },
  {
    value: '+39',
    text: __('Italy'),
    code: 'it',
    flag: '🇮🇹'
  },
  {
    value: '+81',
    text: __('Japan'),
    code: 'jp',
    flag: '🇯🇵'
  },
  {
    value: '+47',
    text: __('Norway'),
    code: 'no',
    flag: '🇳🇴'
  },
  {
    value: '+595',
    text: __('Paraguay'),
    code: 'py',
    flag: '🇵🇾'
  },
  {
    value: '+351',
    text: __('Portugal'),
    code: 'pt',
    flag: '🇵🇹'
  },
  {
    value: '+7',
    text: __('Russia'),
    code: 'ru',
    flag: '🇷🇺'
  },
  {
    value: '+46',
    text: __('Sweden'),
    code: 'se',
    flag: '🇸🇪'
  },
  {
    value: '+1',
    text: __('United States'),
    code: 'us',
    flag: '🇺🇸'
  },
  {
    value: '+7 840',
    text: 'Abkhazia',
    code: 'ge-ab',
    flag: ''
  },
  {
    value: '+93',
    text: 'Afghanistan',
    code: 'af',
    flag: '🇦🇫'
  },
  {
    value: '+355',
    text: 'Albania',
    code: 'al',
    flag: '🇦🇱'
  },
  {
    value: '+213',
    text: 'Algeria',
    code: 'dz',
    flag: '🇩🇿'
  },
  {
    value: '+1 684',
    text: 'American Samoa',
    code: 'as',
    flag: '🇦🇸'
  },
  {
    value: '+376',
    text: 'Andorra',
    code: 'ad',
    flag: '🇦🇩'
  },
  {
    value: '+244',
    text: 'Angola',
    code: 'ao',
    flag: '🇦🇴'
  },
  {
    value: '+1 264',
    text: 'Anguilla',
    code: 'ai',
    flag: '🇦🇮'
  },
  {
    value: '+1 268',
    text: 'Antigua and Barbuda',
    code: 'ag',
    flag: '🇦🇬'
  },
  {
    value: '+374',
    text: 'Armenia',
    code: 'am',
    flag: '🇦🇲'
  },
  {
    value: '+297',
    text: 'Aruba',
    code: 'aw',
    flag: '🇦🇼'
  },
  {
    value: '+247',
    text: 'Ascension',
    code: 'sh',
    flag: '🇸🇭'
  },
  {
    value: '+672',
    text: 'Australian External Territories',
    code: '',
    flag: ''
  },
  {
    value: '+43',
    text: 'Austria',
    code: 'at',
    flag: '🇦🇹'
  },
  {
    value: '+994',
    text: 'Azerbaijan',
    code: 'az',
    flag: '🇦🇿'
  },
  {
    value: '+1 242',
    text: 'Bahamas',
    code: 'bs',
    flag: '🇧🇸'
  },
  {
    value: '+973',
    text: 'Bahrain',
    code: 'bh',
    flag: '🇧🇭'
  },
  {
    value: '+880',
    text: 'Bangladesh',
    code: 'bd',
    flag: '🇧🇩'
  },
  {
    value: '+1 246',
    text: 'Barbados',
    code: 'bb',
    flag: '🇧🇧'
  },
  {
    value: '+1 268 ',
    text: 'Barbuda',
    code: 'ag',
    flag: '🇦🇬'
  },
  {
    value: '+375',
    text: 'Belarus',
    code: 'by',
    flag: '🇧🇾'
  },
  {
    value: '+32',
    text: 'Belgium',
    code: 'be',
    flag: '🇧🇪'
  },
  {
    value: '+501',
    text: 'Belize',
    code: 'bz',
    flag: '🇧🇿'
  },
  {
    value: '+229',
    text: 'Benin',
    code: 'bj',
    flag: '🇧🇯'
  },
  {
    value: '+1 441',
    text: 'Bermuda',
    code: 'bm',
    flag: '🇧🇲'
  },
  {
    value: '+975',
    text: 'Bhutan',
    code: 'bt',
    flag: '🇧🇹'
  },
  {
    value: '+591',
    text: 'Bolivia',
    code: 'bo',
    flag: '🇧🇴'
  },
  {
    value: '+387',
    text: 'Bosnia and Herzegovina',
    code: 'ba',
    flag: '🇧🇦'
  },
  {
    value: '+267',
    text: 'Botswana',
    code: 'bw',
    flag: '🇧🇼'
  },
  {
    value: '+246',
    text: 'British Indian Ocean Territory',
    code: 'io',
    flag: '🇮🇴'
  },
  {
    value: '+1 284',
    text: 'British Virgin Islands',
    code: 'vg',
    flag: '🇻🇬'
  },
  {
    value: '+673',
    text: 'Brunei',
    code: 'bn',
    flag: '🇧🇳'
  },
  {
    value: '+359',
    text: 'Bulgaria',
    code: 'bg',
    flag: '🇧🇬'
  },
  {
    value: '+226',
    text: 'Burkina Faso',
    code: 'bf',
    flag: '🇧🇫'
  },
  {
    value: '+257',
    text: 'Burundi',
    code: 'bi',
    flag: '🇧🇮'
  },
  {
    value: '+855',
    text: 'Cambodia',
    code: 'kh',
    flag: '🇰🇭'
  },
  {
    value: '+237',
    text: 'Cameroon',
    code: 'cm',
    flag: '🇨🇲'
  },
  {
    value: '+238',
    text: 'Cape Verde',
    code: 'CV',
    flag: ''
  },
  {
    value: '+ 345',
    text: 'Cayman Islands',
    code: 'ky',
    flag: '🇰🇾'
  },
  {
    value: '+236',
    text: 'Central African Republic',
    code: 'cf',
    flag: '🇨🇫'
  },
  {
    value: '+235',
    text: 'Chad',
    code: 'td',
    flag: '🇹🇩'
  },
  {
    value: '+61 ',
    text: 'Christmas Island',
    code: 'cx',
    flag: '🇨🇽'
  },
  {
    value: '+61  ',
    text: 'Cocos-Keeling Islands',
    code: 'cc',
    flag: '🇨🇨'
  },
  {
    value: '+269',
    text: 'Comoros',
    code: 'km',
    flag: '🇰🇲'
  },
  {
    value: '+242',
    text: 'Congo',
    code: 'cg',
    flag: '🇨🇬'
  },
  {
    value: '+243',
    text: 'Congo, Dem. Rep. of (Zaire)',
    code: 'cd',
    flag: '🇨🇩'
  },
  {
    value: '+682',
    text: 'Cook Islands',
    code: 'ck',
    flag: '🇨🇰'
  },
  {
    value: '+506',
    text: 'Costa Rica',
    code: 'cr',
    flag: '🇨🇷'
  },
  {
    value: '+385',
    text: 'Croatia',
    code: 'hr',
    flag: '🇭🇷'
  },
  {
    value: '+53',
    text: 'Cuba',
    code: 'cu',
    flag: '🇨🇺'
  },
  {
    value: '+599',
    text: 'Curacao',
    code: 'cw',
    flag: '🇨🇼'
  },
  {
    value: '+537',
    text: 'Cyprus',
    code: 'cy',
    flag: '🇨🇾'
  },
  {
    value: '+420',
    text: 'Czech Republic',
    code: 'cz',
    flag: '🇨🇿'
  },
  {
    value: '+45',
    text: 'Denmark',
    code: 'dk',
    flag: '🇩🇰'
  },
  {
    value: '+246 ',
    text: 'Diego Garcia',
    code: '',
    flag: ''
  },
  {
    value: '+253',
    text: 'Djibouti',
    code: 'dj',
    flag: '🇩🇯'
  },
  {
    value: '+1 767',
    text: 'Dominica',
    code: 'dm',
    flag: '🇩🇲'
  },
  {
    value: '+1 809',
    text: 'Dominican Republic',
    code: 'do',
    flag: '🇩🇴'
  },
  {
    value: '+670',
    text: 'East Timor',
    code: 'tl',
    flag: '🇹🇱'
  },
  {
    value: '+56 ',
    text: 'Easter Island',
    code: '',
    flag: ''
  },
  {
    value: '+593',
    text: 'Ecuador',
    code: 'ec',
    flag: '🇪🇨'
  },
  {
    value: '+20',
    text: 'Egypt',
    code: 'eg',
    flag: '🇪🇬'
  },
  {
    value: '+503',
    text: 'El Salvador',
    code: 'sv',
    flag: '🇸🇻'
  },
  {
    value: '+240',
    text: 'Equatorial Guinea',
    code: 'gq',
    flag: '🇬🇶'
  },
  {
    value: '+291',
    text: 'Eritrea',
    code: 'er',
    flag: '🇪🇷'
  },
  {
    value: '+372',
    text: 'Estonia',
    code: 'ee',
    flag: '🇪🇪'
  },
  {
    value: '+251',
    text: 'Ethiopia',
    code: 'et',
    flag: '🇪🇹'
  },
  {
    value: '+500',
    text: 'Falkland Islands',
    code: 'fk',
    flag: '🇫🇰'
  },
  {
    value: '+298',
    text: 'Faroe Islands',
    code: 'fo',
    flag: '🇫🇴'
  },
  {
    value: '+679',
    text: 'Fiji',
    code: 'fj',
    flag: '🇫🇯'
  },
  {
    value: '+358',
    text: 'Finland',
    code: 'fi',
    flag: '🇫🇮'
  },
  {
    value: '+33',
    text: 'France',
    code: 'fr',
    flag: '🇫🇷'
  },
  {
    value: '+596',
    text: 'French Antilles',
    code: '',
    flag: ''
  },
  {
    value: '+594',
    text: 'French Guiana',
    code: 'gf',
    flag: '🇬🇫'
  },
  {
    value: '+689',
    text: 'French Polynesia',
    code: 'pf',
    flag: '🇵🇫'
  },
  {
    value: '+241',
    text: 'Gabon',
    code: 'ga',
    flag: '🇬🇦'
  },
  {
    value: '+220',
    text: 'Gambia',
    code: 'gm',
    flag: '🇬🇲'
  },
  {
    value: '+995',
    text: 'Georgia',
    code: 'ge',
    flag: '🇬🇪'
  },
  {
    value: '+49',
    text: 'Germany',
    code: 'de',
    flag: '🇩🇪'
  },
  {
    value: '+233',
    text: 'Ghana',
    code: 'gh',
    flag: '🇬🇭'
  },
  {
    value: '+350',
    text: 'Gibraltar',
    code: 'gi',
    flag: '🇬🇮'
  },
  {
    value: '+30',
    text: 'Greece',
    code: 'gr',
    flag: '🇬🇷'
  },
  {
    value: '+299',
    text: 'Greenland',
    code: 'gl',
    flag: '🇬🇱'
  },
  {
    value: '+1 473',
    text: 'Grenada',
    code: 'gd',
    flag: '🇬🇩'
  },
  {
    value: '+590',
    text: 'Guadeloupe',
    code: 'gp',
    flag: '🇬🇵'
  },
  {
    value: '+1 671',
    text: 'Guam',
    code: 'gu',
    flag: '🇬🇺'
  },
  {
    value: '+502',
    text: 'Guatemala',
    code: 'gt',
    flag: '🇬🇹'
  },
  {
    value: '+224',
    text: 'Guinea',
    code: 'gq',
    flag: '🇬🇶'
  },
  {
    value: '+245',
    text: 'Guinea-Bissau',
    code: 'gw',
    flag: '🇬🇼'
  },
  {
    value: '+595 ',
    text: 'Guyana',
    code: 'gy',
    flag: '🇬🇾'
  },
  {
    value: '+509',
    text: 'Haiti',
    code: 'ht',
    flag: '🇭🇹'
  },
  {
    value: '+504',
    text: 'Honduras',
    code: 'hn',
    flag: '🇭🇳'
  },
  {
    value: '+852',
    text: 'Hong Kong SAR China',
    code: 'hkg',
    flag: '🇭🇰'
  },
  {
    value: '+36',
    text: 'Hungary',
    code: 'hu',
    flag: '🇭🇺'
  },
  {
    value: '+354',
    text: 'Iceland',
    code: 'is',
    flag: '🇮🇸'
  },
  {
    value: '+91',
    text: 'India',
    code: 'io',
    flag: '🇮🇴'
  },
  {
    value: '+62',
    text: 'Indonesia',
    code: 'id',
    flag: '🇮🇩'
  },
  {
    value: '+98',
    text: 'Iran',
    code: 'ir',
    flag: '🇮🇷'
  },
  {
    value: '+964',
    text: 'Iraq',
    code: 'iq',
    flag: '🇮🇶'
  },
  {
    value: '+353',
    text: 'Ireland',
    code: 'ie',
    flag: '🇮🇪'
  },
  {
    value: '+972',
    text: 'Israel',
    code: 'il',
    flag: '🇮🇱'
  },
  {
    value: '+225',
    text: 'Ivory Coast',
    code: 'ci',
    flag: '🇨🇮'
  },
  {
    value: '+1 876',
    text: 'Jamaica',
    code: 'jm',
    flag: '🇯🇲'
  },
  {
    value: '+962',
    text: 'Jordan',
    code: 'jo',
    flag: '🇯🇴'
  },
  {
    value: '+7 7',
    text: 'Kazakhstan',
    code: 'kz',
    flag: '🇰🇿'
  },
  {
    value: '+254',
    text: 'Kenya',
    code: 'ke',
    flag: '🇰🇪'
  },
  {
    value: '+686',
    text: 'Kiribati',
    code: 'ki',
    flag: '🇰🇮'
  },
  {
    value: '+965',
    text: 'Kuwait',
    code: 'kw',
    flag: '🇰🇼'
  },
  {
    value: '+996',
    text: 'Kyrgyzstan',
    code: 'kg',
    flag: '🇰🇬'
  },
  {
    value: '+856',
    text: 'Laos',
    code: 'la',
    flag: '🇱🇦'
  },
  {
    value: '+371',
    text: 'Latvia',
    code: 'lv',
    flag: '🇱🇻'
  },
  {
    value: '+961',
    text: 'Lebanon',
    code: 'lb',
    flag: '🇱🇧'
  },
  {
    value: '+266',
    text: 'Lesotho',
    code: 'ls',
    flag: '🇱🇸'
  },
  {
    value: '+231',
    text: 'Liberia',
    code: 'lr',
    flag: '🇱🇷'
  },
  {
    value: '+218',
    text: 'Libya',
    code: 'ly',
    flag: '🇱🇾'
  },
  {
    value: '+423',
    text: 'Liechtenstein',
    code: 'li',
    flag: '🇱🇮'
  },
  {
    value: '+370',
    text: 'Lithuania',
    code: 'lt',
    flag: '🇱🇹'
  },
  {
    value: '+352',
    text: 'Luxembourg',
    code: 'lu',
    flag: '🇱🇺'
  },
  {
    value: '+853',
    text: 'Macau SAR China',
    code: 'mac',
    flag: '🇲🇴'
  },
  {
    value: '+389',
    text: 'Macedonia',
    code: 'mk',
    flag: '🇲🇰'
  },
  {
    value: '+261',
    text: 'Madagascar',
    code: 'mg',
    flag: '🇲🇬'
  },
  {
    value: '+265',
    text: 'Malawi',
    code: 'mw',
    flag: '🇲🇼'
  },
  {
    value: '+60',
    text: 'Malaysia',
    code: 'my',
    flag: '🇲🇾'
  },
  {
    value: '+960',
    text: 'Maldives',
    code: 'mv',
    flag: '🇲🇻'
  },
  {
    value: '+223',
    text: 'Mali',
    code: 'ml',
    flag: '🇲🇱'
  },
  {
    value: '+356',
    text: 'Malta',
    code: 'mt',
    flag: '🇲🇹'
  },
  {
    value: '+692',
    text: 'Marshall Islands',
    code: 'mh',
    flag: '🇲🇭'
  },
  {
    value: '+596 ',
    text: 'Martinique',
    code: 'mq',
    flag: '🇲🇶'
  },
  {
    value: '+222',
    text: 'Mauritania',
    code: 'mr',
    flag: '🇲🇷'
  },
  {
    value: '+230',
    text: 'Mauritius',
    code: 'mu',
    flag: '🇲🇺'
  },
  {
    value: '+262',
    text: 'Mayotte',
    code: 'yt',
    flag: '🇾🇹'
  },
  {
    value: '+52',
    text: 'Mexico',
    code: 'mx',
    flag: '🇲🇽'
  },
  {
    value: '+691',
    text: 'Micronesia',
    code: 'fm',
    flag: '🇫🇲'
  },
  {
    value: '+1 808',
    text: 'Midway Island',
    code: '',
    flag: ''
  },
  {
    value: '+373',
    text: 'Moldova',
    code: 'md',
    flag: '🇲🇩'
  },
  {
    value: '+377',
    text: 'Monaco',
    code: 'mc',
    flag: '🇲🇨'
  },
  {
    value: '+976',
    text: 'Mongolia',
    code: 'mn',
    flag: '🇲🇳'
  },
  {
    value: '+382',
    text: 'Montenegro',
    code: 'me',
    flag: '🇲🇪'
  },
  {
    value: '+1664',
    text: 'Montserrat',
    code: 'ms',
    flag: '🇲🇸'
  },
  {
    value: '+212',
    text: 'Morocco',
    code: 'ma',
    flag: '🇲🇦'
  },
  {
    value: '+95',
    text: 'Myanmar',
    code: 'mm',
    flag: '🇲🇲'
  },
  {
    value: '+264',
    text: 'Namibia',
    code: 'na',
    flag: '🇳🇦'
  },
  {
    value: '+674',
    text: 'Nauru',
    code: 'nr',
    flag: '🇳🇷'
  },
  {
    value: '+977',
    text: 'Nepal',
    code: 'np',
    flag: '🇳🇵'
  },
  {
    value: '+31',
    text: 'Netherlands',
    code: 'nl',
    flag: '🇳🇱'
  },
  {
    value: '+599 ',
    text: 'Netherlands Antilles',
    code: 'an',
    flag: ''
  },
  {
    value: '+1 869',
    text: 'Nevis',
    code: 'kn',
    flag: '🇰🇳'
  },
  {
    value: '+687',
    text: 'New Caledonia',
    code: 'nc',
    flag: '🇳🇨'
  },
  {
    value: '+64',
    text: 'New Zealand',
    code: 'nz',
    flag: '🇳🇿'
  },
  {
    value: '+505',
    text: 'Nicaragua',
    code: 'ni',
    flag: '🇳🇮'
  },
  {
    value: '+227',
    text: 'Niger',
    code: 'ne',
    flag: '🇳🇪'
  },
  {
    value: '+234',
    text: 'Nigeria',
    code: 'ng',
    flag: '🇳🇬'
  },
  {
    value: '+683',
    text: 'Niue',
    code: 'nu',
    flag: '🇳🇺'
  },
  {
    value: '+672 ',
    text: 'Norfolk Island',
    code: 'nf',
    flag: '🇳🇫'
  },
  {
    value: '+850',
    text: 'North Korea',
    code: 'PRK',
    flag: '🇰🇵'
  },
  {
    value: '+1 670',
    text: 'Northern Mariana Islands',
    code: 'mp',
    flag: '🇲🇵'
  },
  {
    value: '+968',
    text: 'Oman',
    code: 'om',
    flag: '🇴🇲'
  },
  {
    value: '+92',
    text: 'Pakistan',
    code: 'pk',
    flag: '🇵🇰'
  },
  {
    value: '+680',
    text: 'Palau',
    code: 'pw',
    flag: '🇵🇼'
  },
  {
    value: '+970',
    text: 'Palestinian Territory',
    code: 'PSE',
    flag: '🇵🇸'
  },
  {
    value: '+507',
    text: 'Panama',
    code: 'pa',
    flag: '🇵🇦'
  },
  {
    value: '+675',
    text: 'Papua New Guinea',
    code: 'pg',
    flag: '🇵🇬'
  },
  {
    value: '+51',
    text: 'Peru',
    code: 'pe',
    flag: '🇵🇪'
  },
  {
    value: '+63',
    text: 'Philippines',
    code: 'ph',
    flag: '🇵🇭'
  },
  {
    value: '+48',
    text: 'Poland',
    code: 'pl',
    flag: '🇵🇱'
  },
  {
    value: '+1 787',
    text: 'Puerto Rico',
    code: 'pr',
    flag: '🇵🇷'
  },
  {
    value: '+974',
    text: 'Qatar',
    code: 'qa',
    flag: '🇶🇦'
  },
  {
    value: '+262 ',
    text: 'Reunion',
    code: 're',
    flag: '🇷🇪'
  },
  {
    value: '+40',
    text: 'Romania',
    code: 'ro',
    flag: '🇷🇴'
  },
  {
    value: '+250',
    text: 'Rwanda',
    code: 'rw',
    flag: '🇷🇼'
  },
  {
    value: '+685',
    text: 'Samoa',
    code: 'as',
    flag: '🇦🇸'
  },
  {
    value: '+378',
    text: 'San Marino',
    code: 'sm',
    flag: '🇸🇲'
  },
  {
    value: '+966',
    text: 'Saudi Arabia',
    code: 'sa',
    flag: '🇸🇦'
  },
  {
    value: '+221',
    text: 'Senegal',
    code: 'sn',
    flag: '🇸🇳'
  },
  {
    value: '+381',
    text: 'Serbia',
    code: 'rs',
    flag: '🇷🇸'
  },
  {
    value: '+248',
    text: 'Seychelles',
    code: 'sc',
    flag: '🇸🇨'
  },
  {
    value: '+232',
    text: 'Sierra Leone',
    code: 'sl',
    flag: '🇸🇱'
  },
  {
    value: '+65',
    text: 'Singapore',
    code: 'sg',
    flag: '🇸🇬'
  },
  {
    value: '+421',
    text: 'Slovakia',
    code: 'sk',
    flag: '🇸🇰'
  },
  {
    value: '+386',
    text: 'Slovenia',
    code: 'si',
    flag: '🇸🇮'
  },
  {
    value: '+677',
    text: 'Solomon Islands',
    code: 'sb',
    flag: '🇸🇧'
  },
  {
    value: '+27',
    text: 'South Africa',
    code: 'za',
    flag: '🇿🇦'
  },
  {
    value: '+500 ',
    text: 'S. Georgia and the South Sandwich Islands',
    code: 'gs',
    flag: '🇬🇸'
  },
  {
    value: '+82',
    text: 'South Korea',
    code: 'kr',
    flag: '🇰🇷'
  },
  {
    value: '+34',
    text: 'Spain',
    code: 'es',
    flag: '🇪🇸'
  },
  {
    value: '+94',
    text: 'Sri Lanka',
    code: 'lk',
    flag: '🇱🇰'
  },
  {
    value: '+249',
    text: 'Sudan',
    code: 'ss',
    flag: '🇸🇸'
  },
  {
    value: '+597',
    text: 'Suritext',
    code: '',
    flag: ''
  },
  {
    value: '+268',
    text: 'Swaziland',
    code: 'sz',
    flag: '🇸🇿'
  },
  {
    value: '+41',
    text: 'Switzerland',
    code: 'ch',
    flag: '🇨🇭'
  },
  {
    value: '+963',
    text: 'Syria',
    code: 'sy',
    flag: '🇸🇾'
  },
  {
    value: '+886',
    text: 'Taiwan',
    code: 'tw',
    flag: '🇹🇼'
  },
  {
    value: '+992',
    text: 'Tajikistan',
    code: 'tj',
    flag: '🇹🇯'
  },
  {
    value: '+255',
    text: 'Tanzania',
    code: 'tz',
    flag: '🇹🇿'
  },
  {
    value: '+66',
    text: 'Thailand',
    code: 'th',
    flag: '🇹🇭'
  },
  {
    value: '+228',
    text: 'Togo',
    code: 'tg',
    flag: '🇹🇬'
  },
  {
    value: '+690',
    text: 'Tokelau',
    code: 'tk',
    flag: '🇹🇰'
  },
  {
    value: '+676',
    text: 'Tonga',
    code: 'to',
    flag: '🇹🇴'
  },
  {
    value: '+1 868',
    text: 'Trinidad and Tobago',
    code: 'tt',
    flag: '🇹🇹'
  },
  {
    value: '+216',
    text: 'Tunisia',
    code: 'tn',
    flag: '🇹🇳'
  },
  {
    value: '+90',
    text: 'Turkey',
    code: 'tr',
    flag: '🇹🇷'
  },
  {
    value: '+993',
    text: 'Turkmenistan',
    code: 'tm',
    flag: '🇹🇲'
  },
  {
    value: '+1 649',
    text: 'Turks and Caicos Islands',
    code: 'tc',
    flag: '🇹🇨'
  },
  {
    value: '+688',
    text: 'Tuvalu',
    code: 'tv',
    flag: '🇹🇻'
  },
  {
    value: '+1 340',
    text: 'U.S. Virgin Islands',
    code: 'vi',
    flag: '🇻🇮'
  },
  {
    value: '+256',
    text: 'Uganda',
    code: 'ug',
    flag: '🇺🇬'
  },
  {
    value: '+380',
    text: 'Ukraine',
    code: 'ua',
    flag: '🇺🇦'
  },
  {
    value: '+971',
    text: 'United Arab Emirates',
    code: 'ae',
    flag: '🇦🇪'
  },
  {
    value: '+44',
    text: 'United Kingdom',
    code: 'gb',
    flag: '🇬🇧'
  },
  {
    value: '+598',
    text: 'Uruguay',
    code: 'uy',
    flag: '🇺🇾'
  },
  {
    value: '+998',
    text: 'Uzbekistan',
    code: 'uz',
    flag: '🇺🇿'
  },
  {
    value: '+678',
    text: 'Vanuatu',
    code: 'vu',
    flag: '🇻🇺'
  },
  {
    value: '+58',
    text: 'Venezuela',
    code: 've',
    flag: '🇻🇪'
  },
  {
    value: '+84',
    text: 'Vietnam',
    code: 'vn',
    flag: '🇻🇳'
  },
  {
    value: '+1 808 ',
    text: 'Wake Island',
    code: '',
    flag: ''
  },
  {
    value: '+681',
    text: 'Wallis and Futuna',
    code: 'wf',
    flag: '🇼🇫'
  },
  {
    value: '+967',
    text: 'Yemen',
    code: 'ye',
    flag: '🇾🇪'
  },
  {
    value: '+260',
    text: 'Zambia',
    code: 'zm',
    flag: '🇿🇲'
  },
  {
    value: '+255 ',
    text: 'Zanzibar',
    code: 'tz',
    flag: ''
  },
  {
    value: '+263',
    text: 'Zimbabwe',
    code: 'zw',
    flag: '🇿🇼'
  }
];

export const countries = [
  {
    value: '+7 840',
    text: 'Abkhazia'
  },
  {
    value: '+93',
    text: 'Afghanistan'
  },
  {
    value: '+355',
    text: 'Albania'
  },
  {
    value: '+213',
    text: 'Algeria'
  },
  {
    value: '+1 684',
    text: 'American Samoa'
  },
  {
    value: '+376',
    text: 'Andorra'
  },
  {
    value: '+244',
    text: 'Angola'
  },
  {
    value: '+1 264',
    text: 'Anguilla'
  },
  {
    value: '+1 268',
    text: 'Antigua and Barbuda'
  },
  {
    value: '+54',
    text: 'Argentina'
  },
  {
    value: '+374',
    text: 'Armenia'
  },
  {
    value: '+297',
    text: 'Aruba'
  },
  {
    value: '+247',
    text: 'Ascension'
  },
  {
    value: '+61',
    text: 'Australia'
  },
  {
    value: '+672',
    text: 'Australian External Territories'
  },
  {
    value: '+43',
    text: 'Austria'
  },
  {
    value: '+994',
    text: 'Azerbaijan'
  },
  {
    value: '+1 242',
    text: 'Bahamas'
  },
  {
    value: '+973',
    text: 'Bahrain'
  },
  {
    value: '+880',
    text: 'Bangladesh'
  },
  {
    value: '+1 246',
    text: 'Barbados'
  },
  {
    value: '+1 268 ',
    text: 'Barbuda'
  },
  {
    value: '+375',
    text: 'Belarus'
  },
  {
    value: '+32',
    text: 'Belgium'
  },
  {
    value: '+501',
    text: 'Belize'
  },
  {
    value: '+229',
    text: 'Benin'
  },
  {
    value: '+1 441',
    text: 'Bermuda'
  },
  {
    value: '+975',
    text: 'Bhutan'
  },
  {
    value: '+591',
    text: 'Bolivia'
  },
  {
    value: '+387',
    text: 'Bosnia and Herzegovina'
  },
  {
    value: '+267',
    text: 'Botswana'
  },
  {
    value: '+55',
    text: __('Brazil')
  },
  {
    value: '+246',
    text: 'British Indian Ocean Territory'
  },
  {
    value: '+1 284',
    text: 'British Virgin Islands'
  },
  {
    value: '+673',
    text: 'Brunei'
  },
  {
    value: '+359',
    text: 'Bulgaria'
  },
  {
    value: '+226',
    text: 'Burkina Faso'
  },
  {
    value: '+257',
    text: 'Burundi'
  },
  {
    value: '+855',
    text: 'Cambodia'
  },
  {
    value: '+237',
    text: 'Cameroon'
  },
  {
    value: '+1',
    text: 'Canada'
  },
  {
    value: '+238',
    text: 'Cape Verde'
  },
  {
    value: '+ 345',
    text: 'Cayman Islands'
  },
  {
    value: '+236',
    text: 'Central African Republic'
  },
  {
    value: '+235',
    text: 'Chad'
  },
  {
    value: '+56',
    text: 'Chile'
  },
  {
    value: '+86',
    text: 'China'
  },
  {
    value: '+61 ',
    text: 'Christmas Island'
  },
  {
    value: '+61  ',
    text: 'Cocos-Keeling Islands'
  },
  {
    value: '+57',
    text: 'Colombia'
  },
  {
    value: '+269',
    text: 'Comoros'
  },
  {
    value: '+242',
    text: 'Congo'
  },
  {
    value: '+243',
    text: 'Congo, Dem. Rep. of (Zaire)'
  },
  {
    value: '+682',
    text: 'Cook Islands'
  },
  {
    value: '+506',
    text: 'Costa Rica'
  },
  {
    value: '+385',
    text: 'Croatia'
  },
  {
    value: '+53',
    text: 'Cuba'
  },
  {
    value: '+599',
    text: 'Curacao'
  },
  {
    value: '+537',
    text: 'Cyprus'
  },
  {
    value: '+420',
    text: 'Czech Republic'
  },
  {
    value: '+45',
    text: 'Denmark'
  },
  {
    value: '+246 ',
    text: 'Diego Garcia'
  },
  {
    value: '+253',
    text: 'Djibouti'
  },
  {
    value: '+1 767',
    text: 'Dominica'
  },
  {
    value: '+1 809',
    text: 'Dominican Republic'
  },
  {
    value: '+670',
    text: 'East Timor'
  },
  {
    value: '+56 ',
    text: 'Easter Island'
  },
  {
    value: '+593',
    text: 'Ecuador'
  },
  {
    value: '+20',
    text: 'Egypt'
  },
  {
    value: '+503',
    text: 'El Salvador'
  },
  {
    value: '+240',
    text: 'Equatorial Guinea'
  },
  {
    value: '+291',
    text: 'Eritrea'
  },
  {
    value: '+372',
    text: 'Estonia'
  },
  {
    value: '+251',
    text: 'Ethiopia'
  },
  {
    value: '+500',
    text: 'Falkland Islands'
  },
  {
    value: '+298',
    text: 'Faroe Islands'
  },
  {
    value: '+679',
    text: 'Fiji'
  },
  {
    value: '+358',
    text: 'Finland'
  },
  {
    value: '+33',
    text: 'France'
  },
  {
    value: '+596',
    text: 'French Antilles'
  },
  {
    value: '+594',
    text: 'French Guiana'
  },
  {
    value: '+689',
    text: 'French Polynesia'
  },
  {
    value: '+241',
    text: 'Gabon'
  },
  {
    value: '+220',
    text: 'Gambia'
  },
  {
    value: '+995',
    text: 'Georgia'
  },
  {
    value: '+49',
    text: 'Germany'
  },
  {
    value: '+233',
    text: 'Ghana'
  },
  {
    value: '+350',
    text: 'Gibraltar'
  },
  {
    value: '+30',
    text: 'Greece'
  },
  {
    value: '+299',
    text: 'Greenland'
  },
  {
    value: '+1 473',
    text: 'Grenada'
  },
  {
    value: '+590',
    text: 'Guadeloupe'
  },
  {
    value: '+1 671',
    text: 'Guam'
  },
  {
    value: '+502',
    text: 'Guatemala'
  },
  {
    value: '+224',
    text: 'Guinea'
  },
  {
    value: '+245',
    text: 'Guinea-Bissau'
  },
  {
    value: '+595 ',
    text: 'Guyana'
  },
  {
    value: '+509',
    text: 'Haiti'
  },
  {
    value: '+504',
    text: 'Honduras'
  },
  {
    value: '+852',
    text: 'Hong Kong SAR China'
  },
  {
    value: '+36',
    text: 'Hungary'
  },
  {
    value: '+354',
    text: 'Iceland'
  },
  {
    value: '+91',
    text: 'India'
  },
  {
    value: '+62',
    text: 'Indonesia'
  },
  {
    value: '+98',
    text: 'Iran'
  },
  {
    value: '+964',
    text: 'Iraq'
  },
  {
    value: '+353',
    text: 'Ireland'
  },
  {
    value: '+972',
    text: 'Israel'
  },
  {
    value: '+39',
    text: 'Italy'
  },
  {
    value: '+225',
    text: 'Ivory Coast'
  },
  {
    value: '+1 876',
    text: 'Jamaica'
  },
  {
    value: '+81',
    text: 'Japan'
  },
  {
    value: '+962',
    text: 'Jordan'
  },
  {
    value: '+7 7',
    text: 'Kazakhstan'
  },
  {
    value: '+254',
    text: 'Kenya'
  },
  {
    value: '+686',
    text: 'Kiribati'
  },
  {
    value: '+965',
    text: 'Kuwait'
  },
  {
    value: '+996',
    text: 'Kyrgyzstan'
  },
  {
    value: '+856',
    text: 'Laos'
  },
  {
    value: '+371',
    text: 'Latvia'
  },
  {
    value: '+961',
    text: 'Lebanon'
  },
  {
    value: '+266',
    text: 'Lesotho'
  },
  {
    value: '+231',
    text: 'Liberia'
  },
  {
    value: '+218',
    text: 'Libya'
  },
  {
    value: '+423',
    text: 'Liechtenstein'
  },
  {
    value: '+370',
    text: 'Lithuania'
  },
  {
    value: '+352',
    text: 'Luxembourg'
  },
  {
    value: '+853',
    text: 'Macau SAR China'
  },
  {
    value: '+389',
    text: 'Macedonia'
  },
  {
    value: '+261',
    text: 'Madagascar'
  },
  {
    value: '+265',
    text: 'Malawi'
  },
  {
    value: '+60',
    text: 'Malaysia'
  },
  {
    value: '+960',
    text: 'Maldives'
  },
  {
    value: '+223',
    text: 'Mali'
  },
  {
    value: '+356',
    text: 'Malta'
  },
  {
    value: '+692',
    text: 'Marshall Islands'
  },
  {
    value: '+596 ',
    text: 'Martinique'
  },
  {
    value: '+222',
    text: 'Mauritania'
  },
  {
    value: '+230',
    text: 'Mauritius'
  },
  {
    value: '+262',
    text: 'Mayotte'
  },
  {
    value: '+52',
    text: 'Mexico'
  },
  {
    value: '+691',
    text: 'Micronesia'
  },
  {
    value: '+1 808',
    text: 'Midway Island'
  },
  {
    value: '+373',
    text: 'Moldova'
  },
  {
    value: '+377',
    text: 'Monaco'
  },
  {
    value: '+976',
    text: 'Mongolia'
  },
  {
    value: '+382',
    text: 'Montenegro'
  },
  {
    value: '+1664',
    text: 'Montserrat'
  },
  {
    value: '+212',
    text: 'Morocco'
  },
  {
    value: '+95',
    text: 'Myanmar'
  },
  {
    value: '+264',
    text: 'Namibia'
  },
  {
    value: '+674',
    text: 'Nauru'
  },
  {
    value: '+977',
    text: 'Nepal'
  },
  {
    value: '+31',
    text: 'Netherlands'
  },
  {
    value: '+599 ',
    text: 'Netherlands Antilles'
  },
  {
    value: '+1 869',
    text: 'Nevis'
  },
  {
    value: '+687',
    text: 'New Caledonia'
  },
  {
    value: '+64',
    text: 'New Zealand'
  },
  {
    value: '+505',
    text: 'Nicaragua'
  },
  {
    value: '+227',
    text: 'Niger'
  },
  {
    value: '+234',
    text: 'Nigeria'
  },
  {
    value: '+683',
    text: 'Niue'
  },
  {
    value: '+672 ',
    text: 'Norfolk Island'
  },
  {
    value: '+850',
    text: 'North Korea'
  },
  {
    value: '+1 670',
    text: 'Northern Mariana Islands'
  },
  {
    value: '+47',
    text: 'Norway'
  },
  {
    value: '+968',
    text: 'Oman'
  },
  {
    value: '+92',
    text: 'Pakistan'
  },
  {
    value: '+680',
    text: 'Palau'
  },
  {
    value: '+970',
    text: 'Palestinian Territory'
  },
  {
    value: '+507',
    text: 'Panama'
  },
  {
    value: '+675',
    text: 'Papua New Guinea'
  },
  {
    value: '+595',
    text: 'Paraguay'
  },
  {
    value: '+51',
    text: 'Peru'
  },
  {
    value: '+63',
    text: 'Philippines'
  },
  {
    value: '+48',
    text: 'Poland'
  },
  {
    value: '+351',
    text: 'Portugal'
  },
  {
    value: '+1 787',
    text: 'Puerto Rico'
  },
  {
    value: '+974',
    text: 'Qatar'
  },
  {
    value: '+262 ',
    text: 'Reunion'
  },
  {
    value: '+40',
    text: 'Romania'
  },
  {
    value: '+7',
    text: 'Russia'
  },
  {
    value: '+250',
    text: 'Rwanda'
  },
  {
    value: '+685',
    text: 'Samoa'
  },
  {
    value: '+378',
    text: 'San Marino'
  },
  {
    value: '+966',
    text: 'Saudi Arabia'
  },
  {
    value: '+221',
    text: 'Senegal'
  },
  {
    value: '+381',
    text: 'Serbia'
  },
  {
    value: '+248',
    text: 'Seychelles'
  },
  {
    value: '+232',
    text: 'Sierra Leone'
  },
  {
    value: '+65',
    text: 'Singapore'
  },
  {
    value: '+421',
    text: 'Slovakia'
  },
  {
    value: '+386',
    text: 'Slovenia'
  },
  {
    value: '+677',
    text: 'Solomon Islands'
  },
  {
    value: '+27',
    text: 'South Africa'
  },
  {
    value: '+500 ',
    text: 'S. Georgia and the South Sandwich Islands'
  },
  {
    value: '+82',
    text: 'South Korea'
  },
  {
    value: '+34',
    text: 'Spain'
  },
  {
    value: '+94',
    text: 'Sri Lanka'
  },
  {
    value: '+249',
    text: 'Sudan'
  },
  {
    value: '+597',
    text: 'Suritext'
  },
  {
    value: '+268',
    text: 'Swaziland'
  },
  {
    value: '+46',
    text: 'Sweden'
  },
  {
    value: '+41',
    text: 'Switzerland'
  },
  {
    value: '+963',
    text: 'Syria'
  },
  {
    value: '+886',
    text: 'Taiwan'
  },
  {
    value: '+992',
    text: 'Tajikistan'
  },
  {
    value: '+255',
    text: 'Tanzania'
  },
  {
    value: '+66',
    text: 'Thailand'
  },
  {
    value: '+228',
    text: 'Togo'
  },
  {
    value: '+690',
    text: 'Tokelau'
  },
  {
    value: '+676',
    text: 'Tonga'
  },
  {
    value: '+1 868',
    text: 'Trinidad and Tobago'
  },
  {
    value: '+216',
    text: 'Tunisia'
  },
  {
    value: '+90',
    text: 'Turkey'
  },
  {
    value: '+993',
    text: 'Turkmenistan'
  },
  {
    value: '+1 649',
    text: 'Turks and Caicos Islands'
  },
  {
    value: '+688',
    text: 'Tuvalu'
  },
  {
    value: '+1 340',
    text: 'U.S. Virgin Islands'
  },
  {
    value: '+256',
    text: 'Uganda'
  },
  {
    value: '+380',
    text: 'Ukraine'
  },
  {
    value: '+971',
    text: 'United Arab Emirates'
  },
  {
    value: '+44',
    text: 'United Kingdom'
  },
  {
    value: '+1',
    text: 'United States'
  },
  {
    value: '+598',
    text: 'Uruguay'
  },
  {
    value: '+998',
    text: 'Uzbekistan'
  },
  {
    value: '+678',
    text: 'Vanuatu'
  },
  {
    value: '+58',
    text: 'Venezuela'
  },
  {
    value: '+84',
    text: 'Vietnam'
  },
  {
    value: '+1 808 ',
    text: 'Wake Island'
  },
  {
    value: '+681',
    text: 'Wallis and Futuna'
  },
  {
    value: '+967',
    text: 'Yemen'
  },
  {
    value: '+260',
    text: 'Zambia'
  },
  {
    value: '+255 ',
    text: 'Zanzibar'
  },
  {
    value: '+263',
    text: 'Zimbabwe'
  }
];

const exports = {
  relevantCountries,
  allCountries,
  countries
};

export default exports;
