import { UnleashClient } from 'unleash-proxy-client';

const proxyUrl = {
  production: 'https://unleash-proxy.classapp.co/proxy',
  stage: 'https://unleash-proxy.classapp.ninja/proxy',
  internal: 'https://unleash-proxy-internal.classapp.ninja/proxy'
};

export default class Unleash {
  /**
   * @description Unleash controller
   * @param {string} env
   */
  constructor(env) {
    this.unleash = new UnleashClient({
      url: proxyUrl[env] ? proxyUrl[env] : proxyUrl.internal,
      clientKey: 'brTxu1Yn3s1M2g6jK',
      appName: 'walle',
    });
    this.isInitialized = false;
    this.error = false;
    this.isReady = false;

    this.unleash.on('initialized', () => {
      this.isInitialized = true;
    });

    this.unleash.on('ready', () => {
      this.error = false;
      this.isReady = true;
    });

    this.unleash.on('error', () => {
      this.error = true;
    });

    this.unleash.start();
  }

  /**
   * @description Returns toggle status
   * @param {string} toggleName
   * @returns {boolean | undefined}
   */
  getStatus(toggleName) {
    try {
      if (this.isReady && this.isInitialized) return this.unleash.isEnabled(toggleName);
      throw new Error('Cannot get Toggle status');
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  /**
   * @description Returns Array with all activated toggles
   * @returns {Array<{
   *  name: string,
   *  impressionData: boolean,
   * }> | undefined}
   */
  getToggles() {
    try {
      if (this.isReady && this.isInitialized) {
        const toggles = this.unleash.getAllToggles();
        return toggles.map(({ name, impressionData }) => ({ name, impressionData }));
      }
      throw new Error('Cannot get Toggle status');
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  /**
   * @description Get unleash context
   * @returns {{
   *  appName: string;
   *  environment?: string | undefined;
   *  userId?: string | undefined;
   *  sessionId?: string | undefined;
   *  remoteAddress?: string | undefined;
   *  properties?: {
   *    [key: string]: string;
   *  } | undefined;
   * } | undefined}
   */
  getContext() {
    try {
      if (this.isReady && this.isInitialized) return this.unleash.getContext();
      throw new Error('Cannot get Unleash Context');
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  /**
   * @description Set unleash context
   * @param {object} context
   */
  setContext(context) {
    let promise;
    try {
      if (this.isInitialized) promise = this.unleash.updateContext(context);
      else throw new Error('Cannot set Unleash Context');
    } catch (err) {
      console.error(err);
      promise = new Promise((resolve) => {
        resolve(undefined);
      });
    }
    return promise;
  }

  /**
   * @description Set a property in unleash context
   * @param {string} field
   * @param {string} value
   */
  setContextField(field, value) {
    try {
      if (this.isReady && this.isInitialized) this.unleash.setContextField(field, value);
      else throw new Error('Cannot set Unleash Context');
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * @description Get variant of a feature toogle
   * @param {string} toggleName
   * @returns {{
   *  enabled: boolean
   *  name: string,
   *  type: string,
   *  value: object | string
   *  }
   *  |
   *  {
   *  name: "disabled",
   *  enabled: false
   *  }
   * }
   */
  async getVariant(toggleName, context = null) {
    if (context) await this.setContext(context);

    return new Promise((resolve) => {
      try {
        if (this.isReady && this.isInitialized) {
          const response = this.unleash.getVariant(toggleName);
          if (response.name === 'disabled') resolve(response);

          const { type } = response.payload;
          resolve({
            enabled: response.enabled,
            name: response.name,
            type,
            value: type === 'json' ? JSON.parse(response.payload.value) : response.payload.value,
          });
        } else throw new Error('Cannot get Unleash Variant');
      } catch (err) {
        console.error(err);
      }
    });
  }
}
