import * as gtag from 'ga-gtag';

/**
 * @typedef {{
 *  ignoreMaster?: boolean,
 * }} AnalyticsUsageRestrictionsType
 */

/**
 * @type {{
 *  canUseAnalytics: (args: {
 *    store: import('../store').Store,
 *    usageRestrictions?: AnalyticsUsageRestrictionsType,
 *  }) => boolean
 * }}
 */
const auxiliarMethods = {
  canUseAnalytics: ({ store, usageRestrictions: { ignoreMaster = true } = {} }) => {
    const isMaster = store.currentUser && store.currentUser.isMaster;
    const isProduction = store.app
      && store.app.env
      && ['production', 'beta'].includes(store.app.env);

    if (ignoreMaster) return !isMaster && !!isProduction;
    return !!isProduction;
  }
};

/**
 * @param {{
 *  store: import('../store').Store,
 *  usageRestrictions?: AnalyticsUsageRestrictionsType,
 * }} params
 */
export const initializeGoogleAnalytics = (params) => {
  if (auxiliarMethods.canUseAnalytics(params)) {
    gtag.install('G-4N5H4M1192');
  }
};

/**
 * @param {{
 *  name: string,
 *  category: string,
 *  label?: string,
 * }} event
 * @param {{
 *  store: import('../store').Store,
 *  usageRestrictions?: AnalyticsUsageRestrictionsType,
 * }} params
 */
export const sendGoogleAnalyticsEvent = ({ name, category, label }, params) => {
  if (auxiliarMethods.canUseAnalytics(params)) {
    gtag.gtag('event', name, {
      event_category: category,
      event_label: label,
    });
  }
};

// this is only being exported for testing reasons
export { auxiliarMethods };
