import { __ } from '../i18n';

export const bannerTheme = {
  // this name will be used for cookies and Google Analytics
  name: 'ActivateChannelsChats',
  content: {
    plainText: [''],
    boldText: __('Chats is here! Provide more management and agility in family service'),
    color: '#FFFFFF',
  },
  actionButton: {
    text: __('Configure'),
    color: '#0069FF',
    backgroundColor: '#FFFFFF',
  },
  backgroundColor: '#FBB13C',
  images: {
    left: '/banner_conversas_esq.png',
    right: '/banner_conversas_dir.png',
  },
  closeButton: {
    color: '#FFFFFF',
  },
};

// This method should be a generic method to get the redirect link, but the rerouting is not
// working properly in ClassPay routes and allows an user without proper permissions to access
// them. So for now, we are using an specific method for ClassPay links
/**
 * @param {{
 *  organizationId: number | string
 *  onlyBanks: boolean
 *  hasAcceptedTerms: boolean
 * }} param0
 * @returns {string}
 */
export const getClassPayBannerRedirectLink = ({
  organizationId, onlyBanks, hasAcceptedTerms
}) => {
  if (onlyBanks) {
    return `/organizations/${organizationId}/accounts`;
  }
  if (hasAcceptedTerms) {
    return `/organizations/${organizationId}/payments`;
  }
  return `/organizations/${organizationId}/paymentTerms`;
};
